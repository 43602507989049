<div class="p-5">
    <h1 class="m-4 text-2xl font-bold">Transacciones Crixto</h1>
    <div class="flex flex-wrap items-center gap-4 bg-gray-100 p-4 rounded-lg shadow-md md:flex-row flex-col">
        <mat-form-field class="w-full md:w-1/4">
            <mat-label>Documento de identidad</mat-label>
            <input matInput [(ngModel)]="idNumberDestination" placeholder="V1111111">
        </mat-form-field>

        <mat-form-field class="w-full md:w-1/4">
            <mat-label>Desde</mat-label>
            <input matInput [matDatepicker]="fromPicker" [(ngModel)]="from">
            <mat-datepicker-toggle matIconSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="w-full md:w-1/4">
            <mat-label>Hasta</mat-label>
            <input matInput [matDatepicker]="toPicker" [(ngModel)]="to">
            <mat-datepicker-toggle matIconSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
        </mat-form-field>

        <div class="w-full md:w-auto">
            <button mat-raised-button color="primary" (click)="loadTransactions()">Buscar</button>
        </div>
    </div>

    <div class="flex flex-col w-full max-h-[60%]">
        <div class="overflow-auto border rounded-lg shadow-md bg-white max-h-[50vh]">
            <table mat-table [dataSource]="dataSource" class="w-full" *ngIf="dataSource">
                <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                    <th mat-header-cell *matHeaderCellDef class=" bg-aguacate-highlight text-center p-2">
                        {{ column.header }}
                    </th>
                    <td mat-cell *matCellDef="let row" class="text-center p-2" [innerHTML]="column.cell(row)">
                    </td>
                </ng-container>
                <ng-container matColumnDef="data">
                    <th mat-header-cell *matHeaderCellDef class="bg-aguacate-highlight text-center p-2">Data</th>
                    <td mat-cell *matCellDef="let row" class="text-center p-2">
                        <button mat-button color="primary" (click)="showData(row.data)">Ver Data</button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="bg-aguacate-highlight text-center p-2">Acciones</th>
                    <td mat-cell *matCellDef="let row" class="text-center p-2">
                        <button
                            *ngIf="!row.status && row.transaccion.estado !== 'RECHAZADA' && row.transaccion.estado !== 'ENVIADA'"
                            mat-button color="accent" (click)="confirmAssignTransaction(row)">
                            Reintentar
                        </button> <button
                            *ngIf="!row.status && row.transaccion.estado!=='RECHAZADA' && row.transaccion.estado!=='ENVIADA'"
                            mat-button color="primary" (click)="openAssignDialog(row)">Reasignar</button>
                        <button
                            *ngIf="!row.status && row.transaccion.estado!=='RECHAZADA' && row.transaccion.estado!=='ENVIADA'"
                            mat-button color="accent" (click)="openRejectTransactionModal(row)">Rechazar</button>
                        <button *ngIf="row.transaccion.estado==='ENVIADA' && !row.data.verificacion" mat-button color="accent" (click)="verifyTransaction(row)">Verificar</button>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-gray-100"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
    <mat-paginator #paginator
      class="self-start mb-4"
      [length]="total"
      [pageSize]="limit"
      [disabled]="false"
      [showFirstLastButtons]="true"
      [pageSizeOptions]="[5, 10, 25, 50, 100]"
      [hidePageSize]="false"
      [pageIndex]="page - 1"
      aria-label="Select page"
      (page)="changePage($event)">
    </mat-paginator>
</div>
<button mat-raised-button color="primary" class="mx-8" (click)="reload()">Refrescar</button>
