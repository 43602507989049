import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { Banco } from 'src/app/dashboard/interfaces/banco';
import { Pais } from 'src/app/dashboard/interfaces/country.interface';
import { CuentaBeneficiario } from 'src/app/dashboard/interfaces/cuenta-beneficiario.interface';
import { TipoCuenta } from 'src/app/dashboard/interfaces/tipo-cuenta.interface';
import { AccountRecipientService } from 'src/app/dashboard/services/account-recipient.service';
import { AccountTypeService } from 'src/app/dashboard/services/account-type.service';
import { BanksService } from 'src/app/dashboard/services/banks.service';
import { CountriesService } from 'src/app/dashboard/services/countries.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.sass'],
})
export default class AddAccountComponent implements OnInit, OnDestroy {
  formCuenta: FormGroup | undefined;
  paisesActivos: Pais[] = [];
  tiposID = ['Cédula de identidad nacional', 'Cédula de identidad extranjera'];
  tiposIDJuridica = ['RIF'];
  selectedTipoID: string = '';
  documentPrefix: string = '';
  tiposCuentas: TipoCuenta[] = [];
  bancos: Banco[] = [];
  usuarioID: number | undefined;
  subscriptions: Subscription = new Subscription();
  esUpdate: boolean = false;
  tipoBeneficiario: number = 0;
  formaEnvio: number = 0;
  viewForm: boolean = false;
  isPersonMovilPay: boolean = false;

  constructor(
    private fb: FormBuilder,
    private countriesService: CountriesService,
    private bancoService: BanksService,
    private tipoCuentaService: AccountTypeService,
    private accountRecipientService: AccountRecipientService,
    private store: Store<AppState>,
    private spinner: SpinnerService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.esUpdate = this.data.type === 'UPDATE';
    this.initFormCuenta();
  }

  ngOnInit() {
    this.viewForm = Boolean(this.esUpdate);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onTipoBeneficiarioChange(value: number): void {
    this.tipoBeneficiario = value;
  }

  onFormaEnvioChange(value: number): void {
    this.formaEnvio = value;
  }

  generateForm() {
    this.viewForm = true;
    this.selectForm();
  }


  async initFormCuenta() {
    if (this.data.userID) {
      this.usuarioID = this.data.userID;
    } else {
      this.subscriptions.add(
        this.store.select('auth').subscribe(({ user }) => {
          this.usuarioID = user?.usuarioId;
        })
      );
    }

    if (this.esUpdate) {
      this.tipoBeneficiario = this.data.cuenta.tipoBeneficiario.tipoBeneficiarioId;
      const prefijo = this.data.cuenta.tipoID;

      if (this.tipoBeneficiario === 0) {
        this.selectedTipoID = prefijo === 'V' ? 'Cédula de identidad nacional' : 'Cédula de identidad extranjera';
      } else {
        this.selectedTipoID = 'RIF';
      }
      this.formCuenta?.get('tipoID')?.setValue(this.selectedTipoID);
      this.onTipoIDChange();
      this.formaEnvio = this.data.cuenta.movilPay;
      this.selectForm();
    }
  }

  async initLists() {
    this.paisesActivos = await this.countriesService.getActiveCountries();
    this.esUpdate ? await this.loadBankData() : await this.setDefaultCountry();
  }

  private async loadBankData() {
    const { banco } = this.data.cuenta;
    const { codigoPais, bancoID } = banco;

    [this.bancos, this.tiposCuentas] = await Promise.all([
      (await this.bancoService.getBanksByCountry(codigoPais)).filter(b => b.codigoBanco !== 'BINANCE'),
      this.tipoCuentaService.getAccountTypes(codigoPais),
    ]);

    const selectedBank = this.bancos.find(b => b.bancoID === bancoID);
    if (selectedBank) {
      this.formCuenta?.get('banco')?.patchValue(selectedBank);
    }
  }


  private async setDefaultCountry() {
    const venezuela = this.paisesActivos.find(pais => pais.codigoPais === 'VE');
    if (venezuela) {
      this.formCuenta?.get('pais')?.setValue(venezuela.codigoPais);
      this.formCuenta?.get('pais')?.disable();
      await this.getBanks();
    }
  }

  async getBanks() {
    const paisSeleccionado = this.formCuenta?.get('pais')?.value;
    if (!paisSeleccionado) return;
    [this.bancos, this.tiposCuentas] = await Promise.all([
      (await this.bancoService.getBanksByCountry(paisSeleccionado)).filter(res => res.codigoBanco !== 'BINANCE'),
      this.tipoCuentaService.getAccountTypes(paisSeleccionado),
    ]);
    this.formCuenta?.get('banco')?.setValue('');
    this.formCuenta?.get('tipoCuenta')?.setValue('');
  }


  onTipoIDChange() {
    this.documentPrefix = '';
    const tipo = this.selectedTipoID.trim();
    if (this.tipoBeneficiario === 0) {
      if (tipo === 'Cédula de identidad nacional') {
        this.documentPrefix = 'V';
      } else {
        this.documentPrefix = 'E';
      }
    }
    if (this.tipoBeneficiario === 1) {
      this.documentPrefix = 'J';
    }
  }

  onBancoChange(banco: Banco): void {
    if (!this.isPersonMovilPay && banco?.codigoBanco) {
      const numeroCuentaActual = this.formCuenta?.get('numeroCuenta')?.value || '';
      const nuevoValor = banco.codigoBanco + numeroCuentaActual.replace(/^\d{4}/, '');
      this.formCuenta?.get('numeroCuenta')?.setValue(nuevoValor);
    }
  }

  onNumeroCuentaInput(event: Event): void {
    const inputEl = event.target as HTMLInputElement;
    const banco = this.formCuenta?.get('banco')?.value;
    if (!banco || !banco.codigoBanco) return;

    const bankCode = banco.codigoBanco;
    let currentValue = inputEl.value;

    if (!currentValue.startsWith(bankCode)) {
      const remainingValue = currentValue.replace(/^\d+/, '');
      currentValue = bankCode + remainingValue;
    }
    if (currentValue.length > 20) {
      currentValue = currentValue.substring(0, 20);
    }
    this.formCuenta?.get('numeroCuenta')?.setValue(currentValue, { emitEvent: false });
    setTimeout(() => {
      inputEl.selectionStart = inputEl.selectionEnd = currentValue.length;
    });
  }



  async guardarBeneficiario() {

    if (this.formCuenta?.invalid) return;
    this.formCuenta?.patchValue({ tipoID: this.documentPrefix });
    let formValue = this.formCuenta?.value;
    let tipoCuentaID = formValue.tipoCuenta;
    let tipoBeneficiario = this.tipoBeneficiario;
    let pagoMovil = this.formaEnvio;

    if (pagoMovil === 1) {
      tipoCuentaID = 1;
    }

    const cuenta: CuentaBeneficiario = {
      ...formValue,
      bancoID: formValue.banco.bancoID,
      tipoCuentaID: tipoCuentaID,
      usuarioID: this.usuarioID,
      tipoBeneficiario: { tipoBeneficiarioId: tipoBeneficiario },
      movilPay: pagoMovil,
      tipoID: formValue.tipoID,
    };

    console.log('Cuenta a guardar:', cuenta);


    if (this.esUpdate) {
      cuenta['cuentaBeneficiarioID'] = this.data.cuenta.cuentaBeneficiarioID;
      delete cuenta.banco;
      delete cuenta.tipoCuenta;
    }
    const ref = this.spinner.start();
    try {
      const resultado = await this.accountRecipientService.saveRecipientAccount(
        cuenta
      );
      if (this.esUpdate) {
        Swal.fire({
          icon: 'success',
          title: 'Beneficiario editado',
          text: 'Su Beneficiario fue editado con éxito',
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonText: 'Entendido',
          confirmButtonColor: '#1e5a3f',

        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Beneficiario creado',
          text: 'Su Beneficiario fue registrado con éxito',
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonText: 'Entendido',
          confirmButtonColor: '#1e5a3f',

        });
      }


      this.dialogRef.close(true);
    } catch (error) {
    }
    this.spinner.stop(ref);
  }

  cancel() {
    this.dialogRef.close();
  }

  back() {
    this.viewForm = false;
  }

  async selectForm() {

    switch (`${this.tipoBeneficiario}-${this.formaEnvio}`) {
      case '0-0':
        return this.getFormPersonBank();
      case '0-1':
        return this.getFormPersonMovilPay();
      case '1-0':
        return this.getFormCompanyBank();
      case '1-1':
        return this.getFormCompanyMovilPay();
      default:
        console.warn('Combinación no válida');
    }
  }

  async getFormPersonBank() {
    this.isPersonMovilPay = false;
    this.formCuenta = this.fb.group({
      pais: new FormControl(this.esUpdate ? this.data.cuenta.banco.codigoPais : '', [Validators.required]),
      nombre: new FormControl(this.esUpdate ? this.data.cuenta.nombre : '', [Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern(/^[A-Za-zÁÉÍÓÚáéíóúñÑ\s]*$/)]),
      apellidos: new FormControl(this.esUpdate ? this.data.cuenta.apellidos : '', [Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern(/^[A-Za-zÁÉÍÓÚáéíóúñÑ\s]*$/)]),
      tipoID: new FormControl(this.esUpdate ? this.data.cuenta.tipoID : '', [Validators.required]),
      numeroID: new FormControl(this.esUpdate ? this.data.cuenta.numeroID : '', [
        Validators.required,
        Validators.maxLength(15),
        Validators.minLength(7),
        Validators.pattern(/^[0-9]+$/)
      ]),
      banco: new FormControl(this.esUpdate ? this.data.cuenta.banco : '', [Validators.required]),
      tipoCuenta: new FormControl(this.esUpdate ? this.data.cuenta.tipoCuentaID : '', [Validators.required]),
      numeroCuenta: new FormControl(this.esUpdate ? this.data.cuenta.numeroCuenta : '', [
        Validators.required,
        Validators.maxLength(20),
        Validators.minLength(20),
        Validators.pattern(/^[0-9]+$/)
      ]),
    });
    this.initLists();
  }

  async getFormPersonMovilPay() {
    this.isPersonMovilPay = true;
    this.formCuenta = this.fb.group({
      pais: new FormControl(this.esUpdate ? this.data.cuenta.banco.codigoPais : '', [Validators.required]),
      nombre: new FormControl(this.esUpdate ? this.data.cuenta.nombre : '', [Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern(/^[A-Za-zÁÉÍÓÚáéíóúñÑ\s]*$/)]),
      apellidos: new FormControl(this.esUpdate ? this.data.cuenta.apellidos : '', [Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern(/^[A-Za-zÁÉÍÓÚáéíóúñÑ\s]*$/)]),
      tipoID: new FormControl(this.esUpdate ? this.data.cuenta.tipoID : '', [Validators.required]),
      numeroID: new FormControl(this.esUpdate ? this.data.cuenta.numeroID : '', [Validators.required, Validators.maxLength(15), Validators.minLength(7), Validators.pattern(/^[0-9]*$/)]),
      banco: new FormControl(this.esUpdate ? this.data.cuenta.banco : '', [Validators.required]),
      tipoCuenta: new FormControl(1),
      numeroCuenta: new FormControl(this.esUpdate ? this.data.cuenta.numeroCuenta : '', [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(10),
        Validators.pattern(/^[0-9]+$/)
      ]),
    });
    this.initLists();
  }

  async getFormCompanyBank() {
    this.isPersonMovilPay = false;
    this.formCuenta = this.fb.group({
      pais: new FormControl(this.esUpdate ? this.data.cuenta.banco.codigoPais : '', [Validators.required]),
      nombre: new FormControl(this.esUpdate ? this.data.cuenta.nombre : '', [Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern(/^[A-Za-zÁÉÍÓÚáéíóúñÑ0-9\s]*$/)]),
      tipoID: new FormControl(this.esUpdate ? this.data.cuenta.tipoID : '', [Validators.required]),
      numeroID: new FormControl(this.esUpdate ? this.data.cuenta.numeroID : '', [Validators.required, Validators.maxLength(15), Validators.minLength(7), Validators.pattern(/^[0-9]*$/)]),
      banco: new FormControl(this.esUpdate ? this.data.cuenta.banco : '', [Validators.required]),
      tipoCuenta: new FormControl(this.esUpdate ? this.data.cuenta.tipoCuentaID : '', [Validators.required]),
      numeroCuenta: new FormControl(this.esUpdate ? this.data.cuenta.numeroCuenta : '', [
        Validators.required,
        Validators.maxLength(20),
        Validators.minLength(20),
        Validators.pattern(/^[0-9]*$/),
      ]),
    });
    this.initLists();
  }

  async getFormCompanyMovilPay() {

    this.isPersonMovilPay = true;
    this.formCuenta = this.fb.group({
      pais: new FormControl(this.esUpdate ? this.data.cuenta.banco.codigoPais : '', [Validators.required]),
      nombre: new FormControl(this.esUpdate ? this.data.cuenta.nombre : '', [Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern(/^[A-Za-zÁÉÍÓÚáéíóúñÑ0-9\s]*$/)]),
      tipoID: new FormControl(this.esUpdate ? this.data.cuenta.tipoID : '', [Validators.required]),
      numeroID: new FormControl(this.esUpdate ? this.data.cuenta.numeroID : '', [Validators.required, Validators.maxLength(15), Validators.minLength(7), Validators.pattern(/^[0-9]*$/)]),
      banco: new FormControl(this.esUpdate ? this.data.cuenta.banco : '', [Validators.required]),
      tipoCuenta: new FormControl(1),
      numeroCuenta: new FormControl(this.esUpdate ? this.data.cuenta.numeroCuenta : '', [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(10),
        Validators.pattern(/^[0-9]+$/)
      ]),
    });
    this.initLists();
  }

  getNumeroIdErrorMessage(field: string): string | null {
    const numeroIDControl = this.formCuenta?.get(field);
    if (!numeroIDControl || !numeroIDControl.errors) return null;


    if (numeroIDControl?.hasError('maxlength')) {
      return 'Máximo de 15 caracteres.';
    }
    if (numeroIDControl?.hasError('minlength')) {
      return 'Mínimo 7 caracteres.';
    }
    if (numeroIDControl?.hasError('required')) {
      return 'Este campo es obligatorio.';
    }
    if (numeroIDControl?.hasError('pattern')) {
      return 'Solo números.';
    }

    return '';
  }


  getErrorMessageName(field: string): string | null {
    const control = this.formCuenta?.get(field);

    if (!control || !control.errors) return null;

    if (control?.hasError('maxlength')) {
      return 'Máximo 40 caracteres';
    }
    if (control?.hasError('minlength')) {
      return 'Mínimo 3 caracteres';
    }
    if (control?.hasError('required')) {
      return 'Este campo es obligatorio';
    }
    if (control?.hasError('pattern')) {
      return 'Sólo caracteres alfabéticos';
    }

    return null;
  }

  getErrorMessageAccountNumber(field: string): string | null {
    const control = this.formCuenta?.get(field);

    if (!control || !control.errors) return null;

    if (control?.hasError('maxlength')) {
      if (this.isPersonMovilPay) {
        return 'Máximo 10 caracteres';
      }
      return 'Máximo 20 caracteres';
    }
    if (control?.hasError('minlength')) {
      if (this.isPersonMovilPay) {
        return 'Máximo 10 caracteres';
      }
      return 'Mínimo 20 caracteres';
    }
    if (control?.hasError('required')) {
      return 'Este campo es obligatorio';
    }
    if (control?.hasError('pattern')) {
      return 'Sólo caracteres numericos';
    }

    return null;
  }


  onlyNumbersID(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    const inputElement = event.target as HTMLInputElement;
    // Si el primer dígito es un 0, lo borra
    if (inputElement.value.length === 0 && charCode === 48) {
      event.preventDefault();
      return;
    }
    // Permite solo números del 1 al 9 en el primer dígito
    if (inputElement.value.length === 0 && (charCode < 49 || charCode > 57)) {
      event.preventDefault();
      return;
    }
    // Permite solo números del 0 al 9 después del primer dígito
    if (inputElement.value.length > 0 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }

  onlyNumbers(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }


  onlyLetters(event: KeyboardEvent) {
    const regex = /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/;
    const key = event.key;

    if (!regex.test(key)) {
      event.preventDefault();
    }
  }

  fieldValueCompany(event: KeyboardEvent) {
    const regex = /^[A-Za-z0-9ÁÉÍÓÚáéíóúÑñ\s]+$/;
    const key = event.key;

    if (!regex.test(key)) {
      event.preventDefault();
    }
  }

  toUpperCase(event: any) {
    setTimeout(() => {
      const input = event.target;
      input.value = input.value.toUpperCase();
      input.dispatchEvent(new Event('input'));
    });
  }



}
