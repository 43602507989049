import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CognitoService } from 'src/app/auth/services/cognito.service';

@Injectable({
  providedIn: 'root'
})
export class RatesAcCRXTService {

  private baseUrl = environment.apiNest;

  constructor(
    private http: HttpClient,
    private cognitoService: CognitoService
  ) { }

  // Método para obtener los headers con el token
  private async getHeaders(): Promise<HttpHeaders> {
    const session = await this.cognitoService.getSession();
    if (!session || !session.accessToken || !session.accessToken.jwtToken) {
      console.error("⚠️ No se pudo obtener el token de sesión");
      return new HttpHeaders().set('Content-Type', 'application/json');
    }

    const token = session.accessToken.jwtToken.trim();
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
    //console.log('Token', token)
    return headers;
  }

  // Método para obtener los valores de la tasa
  async getValuesRateCrx(): Promise<{ data: any; any: any }> {
    const headers = await this.getHeaders();
    const serviceURL = `${this.baseUrl}ag-rate-auto/values-auto-ag`;
    return firstValueFrom(this.http.get<any>(serviceURL, { headers }));
  }

  // Método para calcular valores automáticamente
  async setCalculateValuesAuto(): Promise<{ data: any; any: any }> {
    const headers = await this.getHeaders();
    const serviceURL = `${this.baseUrl}ag-rate-auto/calculate-values-auto-ag`;
    return firstValueFrom(this.http.get<any>(serviceURL, { headers }));
  }

  // Método para cambiar valores manualmente
  async setChangeValueManual(): Promise<{ data: any; any: any }> {
    const headers = await this.getHeaders();
    const serviceURL = `${this.baseUrl}ag-rate-auto/change-value-manual`;
    return firstValueFrom(this.http.get<any>(serviceURL, { headers }));
  }

  // Método para calcular valores manuales con un payload
  async postCalculateValuesManual(tasas: { feeCRX: number; tasaCLP: number; tasaCRXT: number; comision: number; email: string }): Promise<any> {
    const headers = await this.getHeaders();
    const serviceURL = `${this.baseUrl}ag-rate-auto/calculate-values-manual-ag`;

    const body = {
      feeCRX: tasas.feeCRX,
      clp: tasas.tasaCLP,
      rateUsd: tasas.tasaCRXT,
      comision: tasas.comision,
      email: tasas.email
    };

    return firstValueFrom(this.http.post<any>(serviceURL, body, { headers }));
  }

  async getTransactions(
    page: number,
    limit: number,
    idNumberDestination?: string,
    from?: string,
    to?: string
  ): Promise<any> {
    const headers = await this.getHeaders();
    const params: Record<string, string> = { page: `${page}`, limit: `${limit}` };

    if (idNumberDestination) params['idNumberDestination'] = idNumberDestination;
    if (from) params['from'] = from;
    if (to) params['to'] = to;

    const serviceURL = `${this.baseUrl}transactions-crixto?${new URLSearchParams(params).toString()}`;

    return firstValueFrom(this.http.get<any>(serviceURL, { headers }));
  }

  async validateTransferDirect(transactionId: number): Promise<any> {
    const headers = await this.getHeaders();
    const serviceURL = `${this.baseUrl}transactions-crixto/validate-transfer-direct?transactionId=${transactionId}`;
    return firstValueFrom(this.http.get<any>(serviceURL, { headers }));
  }

}
