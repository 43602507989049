<div class="flex flex-row flex-wrap justify-between">
    <div class="w-1/3 overflow-auto place-content-center min-w-[330px] max-w-[640px] max-h-[380px]">
        <h1 class="text-2xl font-bold mx-14 my-7 text-center  h-[95px]">Operaciones por procesar</h1>
        <div class="p-2">
            <div class="w-full flex flex-row gap-2 flex-wrap text-center place-content-center">
                <div class="clickable-card" (click)="redirect('/wallet/admin/pending-transactons-cripto')">
                    <h2 class="text-lg font-bold" [ngClass]="{
                        'blinking-text': this.pendingCount && this.pendingCount?.CRIPTO>30,
                        'blinking-text2': this.pendingCount && this.pendingCount?.CRIPTO<=30&&this.pendingCount?.CRIPTO>15
                      }">
                        USDT
                    </h2>
                    <p [ngClass]="{
                        'blinking-text': this.pendingCount && this.pendingCount?.CRIPTO>30,
                        'blinking-text2': this.pendingCount && this.pendingCount?.CRIPTO<=30&&this.pendingCount?.CRIPTO>15
                      }">{{this.pendingCount ? this.pendingCount?.CRIPTO : 'Cargando'}}</p>
                    <h4 class="italic">
                        Pendientes
                    </h4>
                </div>

                <div class="clickable-card" (click)="redirect('/wallet/admin/transactions')">

                    <h2 class="text-lg font-bold" [ngClass]="{
                        'blinking-text': this.pendingCount && this.pendingCount?.FIAT>30,
                        'blinking-text2': this.pendingCount && this.pendingCount?.FIAT<=30&&this.pendingCount?.FIAT>15
                      }">
                        FIAT bolivares
                    </h2>
                    <p [ngClass]="{
                        'blinking-text': this.pendingCount && this.pendingCount?.FIAT>30,
                        'blinking-text2': this.pendingCount && this.pendingCount?.FIAT<=30&&this.pendingCount?.FIAT>15
                      }">{{this.pendingCount ? this.pendingCount?.FIAT : 'Cargando'}}</p>
                    <h4 class="italic">
                        Pendientes
                    </h4>
                </div>

                <div class="clickable-card" (click)="redirect('/wallet/admin/pending-transactions-zelle')">

                    <h2 class="text-lg font-bold" [ngClass]="{
                        'blinking-text': this.pendingCount && this.pendingCount?.ZELLE>30,
                        'blinking-text2': this.pendingCount && this.pendingCount?.ZELLE<=30&&this.pendingCount?.ZELLE>15
                      }">
                        Zelle
                    </h2>
                    <p [ngClass]="{
                        'blinking-text': this.pendingCount && this.pendingCount?.ZELLE>30,
                        'blinking-text2': this.pendingCount && this.pendingCount?.ZELLE<=30&&this.pendingCount?.ZELLE>15
                      }">{{this.pendingCount ? this.pendingCount?.ZELLE : 'Cargando'}}</p>
                    <h4 class="italic">
                        Pendientes
                    </h4>
                </div>

                <div class="clickable-card" (click)="redirect('/wallet/admin/withdraw-transactions')">

                    <h2 class="text-lg font-bold" [ngClass]="{
                        'blinking-text': this.pendingCount && this.pendingCount?.RETIRO>30,
                        'blinking-text2': this.pendingCount && this.pendingCount?.RETIRO<=30&&this.pendingCount?.RETIRO>15
                      }">
                        Retiro FIAT
                    </h2>
                    <p [ngClass]="{
                        'blinking-text': this.pendingCount && this.pendingCount?.RETIRO>30,
                        'blinking-text2': this.pendingCount && this.pendingCount?.RETIRO<=30&&this.pendingCount?.RETIRO>15
                      }">{{this.pendingCount ? this.pendingCount?.RETIRO : 'Cargando'}}</p>
                    <h4 class="italic">
                        Pendientes
                    </h4>
                </div>

            </div>
        </div>

    </div>

    <div class="w-1/3 overflow-auto place-content-center min-w-[330px] max-w-[640px] max-h-[380px]">
        <h1 class="text-2xl font-bold mx-14 my-7 text-center  h-[95px]">Operaciones completadas hoy 3
            {{formatDate(todayDate) }}</h1>
        <div class="p-2">
            <div class="w-full flex flex-row gap-2 flex-wrap text-center place-content-center">
                <div class="rounded-[25px] border-2 border-aguacate-green p-3 w-[150px]">
                    <h2 class="text-lg font-bold">
                        USDT
                    </h2>
                    <p>{{this.completedCount?.currentDay ? this.completedCount?.currentDay.CRIPTO : 'Cargando'}}</p>
                    <h4 class="italic">
                        Completadas
                    </h4>
                </div>

                <div class="rounded-[25px] border-2 border-aguacate-green p-3 w-[150px]">
                    <h2 class="text-lg font-bold">
                        FIAT bolivares
                    </h2>
                    <p>{{this.completedCount?.currentDay ? this.completedCount?.currentDay.FIAT : 'Cargando'}}</p>
                    <h4 class="italic">
                        Completadas
                    </h4>
                </div>

                <div class="rounded-[25px] border-2 border-aguacate-green p-3 w-[150px]">
                    <h2 class="text-lg font-bold">
                        Zelle
                    </h2>
                    <p>{{this.completedCount?.currentDay ? this.completedCount?.currentDay.ZELLE : 'Cargando'}}</p>
                    <h4 class="italic">
                        Completadas
                    </h4>
                </div>

                <div class="rounded-[25px] border-2 border-aguacate-green p-3 w-[150px]">
                    <h2 class="text-lg font-bold">
                        Retiro FIAT
                    </h2>
                    <p>{{this.completedCount?.currentDay ? this.completedCount?.currentDay.RETIRO : 'Cargando'}}</p>
                    <h4 class="italic">
                        Completadas
                    </h4>
                </div>
            </div>
        </div>

    </div>
    <div class="w-1/3 overflow-auto place-content-center min-w-[330px] max-w-[640px] max-h-[380px]">
        <h1 class="text-2xl font-bold mx-14 my-7 text-center h-[95px]">Operaciones completadas ayer
            {{formatDate(yesterdayDate) }}</h1>
        <div class="p-2">
            <div class="w-full flex flex-row gap-2 flex-wrap text-center place-content-center">
                <div class="rounded-[25px] border-2 border-aguacate-green p-3 w-[150px]">
                    <h2 class="text-lg font-bold">
                        USDT
                    </h2>
                    <p>{{this.completedCount?.previousDay ? this.completedCount?.previousDay.CRIPTO : 'Cargando'}}</p>
                    <h4 class="italic">
                        Completadas
                    </h4>
                </div>

                <div class="rounded-[25px] border-2 border-aguacate-green p-3 w-[150px]">
                    <h2 class="text-lg font-bold">
                        FIAT bolivares
                    </h2>
                    <p>{{this.completedCount?.previousDay ? this.completedCount?.previousDay.FIAT : 'Cargando'}}</p>
                    <h4 class="italic">
                        Completadas
                    </h4>
                </div>

                <div class="rounded-[25px] border-2 border-aguacate-green p-3 w-[150px]">
                    <h2 class="text-lg font-bold">
                        Zelle
                    </h2>
                    <p>{{this.completedCount?.previousDay ? this.completedCount?.previousDay.ZELLE : 'Cargando'}}</p>
                    <h4 class="italic">
                        Completadas
                    </h4>
                </div>

                <div class="rounded-[25px] border-2 border-aguacate-green p-3 w-[150px]">
                    <h2 class="text-lg font-bold">
                        Retiro FIAT
                    </h2>
                    <p>{{this.completedCount?.previousDay ? this.completedCount?.previousDay.RETIRO : 'Cargando'}}</p>
                    <h4 class="italic">
                        Completadas
                    </h4>
                </div>

            </div>
        </div>

    </div>
</div>

<div class="h-1/2 overflow-auto">
    <h1 class="text-2xl font-bold mx-14 my-2">Tasas Publicadas</h1>
    <div *ngIf="currentValues && currentRates" class="flex flex-col md:flex-row gap-4 mx-4">
        <div
            class="w-full md:max-w-[250px] mx-4 text-xl font-bold rounded-[25px] border-solid border-2 border-aguacate-green my-0 text-center content-center p-4">
            <div class="rounded-md bg-aguacate-highlight">
                <h1 class="text-lg font-bold">
                    USDT/CLP
                </h1>
            </div>
            <h2 class="text-lg font-bold">
                {{ this.usdt_clp_tax | number : "1.2-2" }}
            </h2>
            <div class="mt-4 rounded-md bg-aguacate-highlight">
                <h1 class="text-lg font-bold">
                    Venta Zelle
                </h1>
            </div>
            <h2 class="text-lg font-bold">
                {{ this.zelleSellValue| number : "1.2-2" }}
            </h2>
            <div class="mt-4 rounded-md bg-aguacate-highlight">
                <h1 class="text-lg font-bold">
                    Compra Zelle
                </h1>
            </div>
            <h2 class="text-lg font-bold">
                {{ this.zelleBuyValue| number : "1.2-2" }}
            </h2>
        </div>

        <div class="w-full md:w-4/5 mx-4 rounded-[25px] border-solid border-2 border-aguacate-green">
            <h1 class="text-lg font-bold mt-4 text-center">
                FIAT
            </h1>
            <div class="flex flex-col xl:flex-row">
                <div class="w-full xl:w-1/2 gap-4 flex-wrap text-center p-4">

                    <div>
                        <h2 class="text-lg font-bold my-4">
                            Mínimo {{ currentValues.limiteInferior | currency:'':'' }} BS
                        </h2>
                    </div>
                    <div class="flex flex-row gap-4 my-4 flex-wrap justify-center">
                        <div class="flex flex-col sm:flex-row gap-4">
                            <app-rate-value-card [bank]="'Banesco'" [rateOrigin]="currentRates.valoresVES.valorBanesco"
                                [imgUrl]="'assets/img/bancos/banesco.png'"
                                [rateDestination]="currentRates.minimo.tasaBanesco"
                                class="w-[200px]"></app-rate-value-card>
                            <app-rate-value-card [bank]="'Mercantil'"
                                [rateOrigin]="currentRates.valoresVES.valorMercantil"
                                [imgUrl]="'assets/img/bancos/mercantil.png'"
                                [rateDestination]="currentRates.minimo.tasaMercantil"
                                class="w-[200px]"></app-rate-value-card>
                        </div>
                        <div class="flex flex-col sm:flex-row gap-4">
                            <app-rate-value-card [bank]="'Provincial'"
                                [rateOrigin]="currentRates.valoresVES.valorProvincial"
                                [imgUrl]="'assets/img/bancos/provincial.png'"
                                [rateDestination]="currentRates.minimo.tasaProvincial"
                                class="w-[200px]"></app-rate-value-card>
                            <app-rate-value-card [bank]="'Venezuela'"
                                [rateOrigin]="currentRates.valoresVES.valorVenezuela"
                                [imgUrl]="'assets/img/bancos/venezuela.png'"
                                [rateDestination]="currentRates.minimo.tasaVenezuela"
                                class="w-[200px]"></app-rate-value-card>
                        </div>
                    </div>
                </div>

                <div class="w-full xl:w-1/2 gap-4 flex-wrap text-center p-4">
                    <div>
                        <h2 class="text-xl font-bold my-4">
                            Mínimo {{ currentValues.limiteSuperior }} BS
                        </h2>
                    </div>
                    <div class="flex flex-row gap-4 my-4 flex-wrap justify-center">
                        <div class="flex flex-col sm:flex-row gap-4">
                            <app-rate-value-card [bank]="'Banesco'" [rateOrigin]="currentRates.valoresVES.valorBanesco"
                                [imgUrl]="'assets/img/bancos/banesco.png'"
                                [rateDestination]="currentRates.maximo.tasaBanesco"
                                class="w-[200px]"></app-rate-value-card>
                            <app-rate-value-card [bank]="'Mercantil'"
                                [rateOrigin]="currentRates.valoresVES.valorMercantil"
                                [imgUrl]="'assets/img/bancos/mercantil.png'"
                                [rateDestination]="currentRates.maximo.tasaMercantil"
                                class="w-[200px]"></app-rate-value-card>
                        </div>
                        <div class="flex flex-col sm:flex-row gap-4">
                            <app-rate-value-card [bank]="'Provincial'"
                                [rateOrigin]="currentRates.valoresVES.valorProvincial"
                                [imgUrl]="'assets/img/bancos/provincial.png'"
                                [rateDestination]="currentRates.maximo.tasaProvincial"
                                class="w-[200px]"></app-rate-value-card>
                            <app-rate-value-card [bank]="'Venezuela'"
                                [rateOrigin]="currentRates.valoresVES.valorVenezuela"
                                [imgUrl]="'assets/img/bancos/venezuela.png'"
                                [rateDestination]="currentRates.maximo.tasaVenezuela"
                                class="w-[200px]"></app-rate-value-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
