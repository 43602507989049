<div class="px-6 py-4 w-full bg-aguacate-bg rounded-lg " [class.bg-aguacate-highlight]="cuenta?.seleccionado">
  <div class="flex flex-row justify-between items-center">
    <div class="text-sm md:text-base"><b>{{cuenta?.nombre}} {{cuenta?.apellidos}} </b></div>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="verCuenta()">
        <mat-icon>search</mat-icon>
        <span>Ver</span>
      </button>
      <button mat-menu-item (click)="actualizar()">
        <mat-icon>edit</mat-icon>
        <span>Editar</span>
      </button>
      <button mat-menu-item (click)="inactivarCuenta()">
        <mat-icon>delete</mat-icon>
        <span>Eliminar</span>
      </button>
    </mat-menu>
  </div>
  <div class="text-sm md:text-base">
    <b> Banco {{cuenta?.banco?.nombre | titlecase}}</b>
    <br><b>País:</b> {{cuenta?.banco?.codigoPais}}
    <br> <b>Tipo de cuenta:</b> {{ cuenta?.movilPay===1? 'Movil Pay' : cuenta?.tipoCuenta?.nombre}}
    <br> <b> {{cuenta?.movilPay?'Numero de Telefono: (+58)':'Numero de Cuenta:'}}</b> {{cuenta?.numeroCuenta}}
  </div>

</div>
