import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { CognitoService } from '../../services/cognito.service';
import { UserService } from 'src/app/dashboard/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.sass']
})
export class RequestPasswordResetComponent {
  mailForm: FormGroup;
  mailSent: boolean = false;
  processError: boolean = false;
  errorMessage: string = '';

  constructor(private fb: FormBuilder, private cognitoService: CognitoService, private userService: UserService, private spinner: SpinnerService) {
    this.mailForm = this.fb.group({
      mail: ['', [Validators.required, this.noSpacesValidator]],
    })

  }
  async onSubmit(form: FormGroup) {
    this.processError = false;
    const spinnerRef = this.spinner.start();
    const status = await this.userService.checkUserValidity(form.get('mail')?.value);
    if (status === 'UNCONFIRMED') {
      this.spinner.stop(spinnerRef);
      form.reset();
      await Swal.fire({
        icon: 'warning',
        title: 'Debe verificar su correo',
        text: 'Para poder realizar un cambio de contraseña debe verificar primero su correo electrónico, por favor revise su bandeja de entrada',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Entendido',
        confirmButtonColor: '#1e5a3f',

      });
      return;
    }
    this.cognitoService.resetPassword(form.get('mail')?.value).then(res => {
      this.processError = false;
      this.mailSent = true;
      this.spinner.stop(spinnerRef);
    }).catch(err => {
      this.spinner.stop(spinnerRef);
      this.processError = true;
      if (err.code === 'LimitExceededException') {
        this.errorMessage = 'Ha excedido el número de intentos, intente más tarde';
        return;
      }
      this.errorMessage = 'Ha ocurrido un error, intente más tarde';
    });
  }

  /** Validador personalizado: No permite espacios en el correo */
  noSpacesValidator(control: AbstractControl): ValidationErrors | null {
    return control.value && control.value.includes(' ') ? { noSpaces: true } : null;
  }

  /** Evita que los usuarios peguen espacios en el campo */
  handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    const text = event.clipboardData?.getData('text') || '';
    this.mailForm.get('email')?.setValue(text.replace(/\s/g, ''));
  }

}
