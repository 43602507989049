<div class="h-screen flex flex-row justify-around items-center content-center gap-4	md:mx-4">
  <div class=" w-11/12 md:w-1/2 xl:w-2/5  h-auto  bg-aguacate-bg rounded-lg shadow  xs:p-0">
    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
      <ng-container *ngIf=" !mailSent; else messageConfirmation">
        <div class="container flex flex-col items-left" >
          <h1 class="font-medium text-2xl">Recupera tu contraseña</h1>
          <br>
          <p class="font-medium">Ingrese su correo electrónico </p>
        </div>
        <form class="space-y-4 md:space-y-6" [formGroup]="mailForm" (ngSubmit)="onSubmit(mailForm)">
          <div>
            <label for="mail" class="block mb-2 text-sm font-medium text-gray-900" >Correo electrónico</label>
            <input type="email" name="mail" id="mail" placeholder="correo@mail.com" formControlName="mail"
            (keydown.space)="$event.preventDefault()" (paste)="handlePaste($event)"
              class="bg-aguacate-green-50 border border-aguacate-green-300 text-primary-900 sm:text-sm rounded-lg focus:ring-aguacate-green-600 focus:border-aguacate-green-600 block w-full p-2.5"
              required="" />
              <div *ngIf="mailForm.get('mail')?.invalid && (mailForm.get('mail')?.dirty || mailForm.get('mail')?.touched)">
                <span *ngIf="mailForm.get('mail')?.hasError('required')" class="text-sm text-red-600"> Ingresa la contraseña </span>
              </div>

          </div>
          <div class="container flex flex-col items-center">
            <button type="submit" [disabled]="mailForm.invalid"
              class="w-1/2 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              Reiniciar contraseña
            </button>
            <span *ngIf="processError" class="text-sm text-red-600 mt-4"> {{errorMessage}}</span>
          </div>
        </form>
      </ng-container>

      <ng-template #messageConfirmation>
        <div class="container flex flex-col items-center">
          <h1 class="font-medium text-2xl">Recupera tu contraseña</h1>
          <p class="py-4">
                Se ha enviado un correo electrónico con las instrucciones para restablecer su
                contraseña. Si no recibe el correo puede solicitarlo nuevamente, si la situación
                persiste comuníquese  con nosotros
          </p>
          <button type="button" [routerLink]="['/auth/login']"
          class="w-1/2 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
          Salir
        </button>
        </div>
      </ng-template>

    </div>
  </div>
</div>
