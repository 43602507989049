import { Component, OnDestroy, OnInit } from '@angular/core';
import { BinanceService } from '../../services/binance.service';
import { MatDialog } from '@angular/material/dialog';
import { SetValuesComponent } from '../admin-rate/manual-rate/set-values/set-values.component';
import { ParameterService } from '../../services/parameter.service';
import { ParametrosEnum } from 'src/app/shared/enums/parameters.enum';
import { Router } from '@angular/router';
import { TransaccionService } from '../../services/transaccion.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-transactions-dashboard',
  templateUrl: './transactions-dashboard.component.html',
  styleUrls: ['./transactions-dashboard.component.css']
})
export class TransactionsDashboardComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  currentRates: any;
  currentValues: any;
  zelleSellValue: number = 0;
  zelleBuyValue: number = 0;
  usdt_clp_tax: number = 0;
  porCompZelle: number = 0;
  descVentaZelle: number = 0;
  spotPrice: number = 0;
  pendingCount: any;
  completedCount: any;
  yesterdayDate: Date = new Date();
  todayDate: Date = new Date();

  constructor(
    private binanceService: BinanceService,
    private dialog: MatDialog,
    private parameterService: ParameterService,
    private router: Router,
    private transactionService: TransaccionService,
    private spinner: SpinnerService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    let ref = this.spinner.start()
    this.getCurrentRates();
    this.getCurrentValues();
    this.spinner.stop(ref);
    this.setupAutoRefresh();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async getCurrentRates() {
    this.currentRates = await this.binanceService.getCurrentRates();
    this.yesterdayDate.setDate(this.todayDate.getDate() - 1);

  }

  async getCurrentValues() {
    this.currentValues = await this.binanceService.getConversionData();
    this.usdt_clp_tax = this.currentValues.tasaUsdtClp;
    await this.parameterService.getParameter(ParametrosEnum.DESCUENTO_COM_ZELLE).then((value) => {
      this.descVentaZelle = value.valor;
    }
    );
    await this.parameterService.getParameter(ParametrosEnum.PRECIO_SPOT).then((value) => {
      this.spotPrice = value.valor;
    }
    );
    await this.parameterService.getParameter(ParametrosEnum.PORC_VENTA_ZELLE).then((value) => {
      this.porCompZelle = value.valor;
    }
    );

    this.zelleSellValue = this.usdt_clp_tax * this.porCompZelle;
    this.zelleBuyValue = this.spotPrice - this.descVentaZelle;
    this.completedCount = await this.transactionService.getCompletedCount();
    this.pendingCount = await this.transactionService.getPendingCount();
  }
  private setupAutoRefresh() {
    interval(30000).pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.getCurrentRates();
      this.getCurrentValues();
    });
  }
  async actualizarTasa() {
    const data = {
      tasaBanesco: this.currentRates.valoresVES.valorBanesco,
      tasaProvincial: this.currentRates.valoresVES.valorBanesco,
      tasaVenezuela: this.currentRates.valoresVES.valorVenezuela,
      tasaMercantil: this.currentRates.valoresVES.valorMercantil,
      tasaCLP: this.currentRates.tasaCLP,
      porcentajeComision: this.currentRates.comision,
      fuente: 'MANUAL'
    }
    this.dialog.open(SetValuesComponent, { data }).afterClosed().subscribe(res => {
      if (res && res === true) {
        this.getCurrentRates();
        this.getCurrentValues();
      }
    })
  }

  redirect(route: string) {
    this.router.navigate([route]);
  }

  formatDate(date: any) {
    const months = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];

    return `${day} ${month}`;
  }

}
