import { createReducer, on } from '@ngrx/store';
import { User } from '../shared/models/user.model';
import * as fromAuth from './auth.actions';

export interface AuthState {
  isLogged: boolean;
  userRole: string;
  user: User | null;
}

const estadoInicial: AuthState = {
  isLogged: false,
  user: null,
  userRole: ''
};

export const authReducer = createReducer(
  estadoInicial,
  on(fromAuth.setUser, (state, { user }) => ({
    ...state,
    user: { ...user },
    isLogged: true,
  })),
  on(fromAuth.setUserAndRole, (state, { user, role }) => ({
    ...state,
    user: { ...user },
    isLogged: true,
    userRole: role
  })),
  on(fromAuth.unsetUser, (state) => ({ ...estadoInicial })), // Cambia esto
  on(fromAuth.setUserRole, (state, { role }) => ({
    ...state,
    userRole: role
  })),
);
