import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { interval, Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { UserRoles } from 'src/app/shared/enums/roles-enum';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';
import { RatesAcService } from '../../services/rates-ac-service.service';
import { RatesAcCRXTService } from "../../services/ac-crxt.service";
import { TransaccionService } from 'src/app/dashboard/services/transaccion.service';
import { EstadosUsuario } from 'src/app/shared/enums/estados-usuario.enum';
import { SetValuesAgComponent } from 'src/app/retail/components/set-values-ag/set-values.component';
import { MatDialog } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-main-retail',
  templateUrl: './main-retail.component.html',
  styleUrls: ['./main-retail.component.sass']
})
export class MainRetailComponent implements OnDestroy, AfterViewInit, OnInit {
  @Input() showCopyToClipboard = false;
  subscription: Subscription = new Subscription();
  userRole: UserRoles = UserRoles.aguacate_retail_client;
  user: any;
  nombreUsuario: string = '';
  userEnum = UserRoles;
  estadoValidacion: number = -1;
  userEmail: string = '';
  currentValues: any[] = [];
  rates: any[] = [];
  pendingCount: any;
  completedCount: any;
  yesterdayDate: Date = new Date();
  todayDate: Date = new Date();
  dataCRXT: any;
  tasas: any[] = [];
  tipoTasa: string | undefined;



  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private spinner: SpinnerService,
    private ratesFiatService: RatesAcService,
    private transactionService: TransaccionService,
    private agCRXTService: RatesAcCRXTService,
    private dialog: MatDialog,
    private ratesAc: RatesAcService,
    private clipboard: Clipboard
  ) {
    let spinnerRef = this.spinner.start();
    this.subscription.add(
      this.store.select('auth').subscribe(({ user, userRole }: any) => {
        this.userRole = userRole;
        this.user = user;
        this.userEmail = user?.email;
        if (this.userRole === UserRoles.aguacate_retail_client) {
          if (!this.isNotNullOrEmpty(user)) {
            this.estadoValidacion = EstadosUsuario.incompleto;
            return;
          }
          if (
            this.isNotNullOrEmpty(user) &&
            [0, 1, 2].includes(user.estadoCompliance.estadoID)
          ) {
            this.estadoValidacion = EstadosUsuario.pendienteValidacion;
          }
          if (
            this.isNotNullOrEmpty(user) &&
            user.estadoCompliance.estadoID === 3
          ) {
            this.estadoValidacion = EstadosUsuario.validado;
          }
        }
      })
    );
    this.loadData();
    this.spinner.stop(spinnerRef);

  }
  ngOnInit() {
    this.loadData();
    this.subscription.add(
      interval(30000).subscribe(() => {
        this.loadData();
      })
    );
  }

  async loadData() {
    this.currentValues = await this.ratesFiatService.getRatesByProfile('client');
    this.yesterdayDate.setDate(this.todayDate.getDate() - 1);
    if (this.userRole === UserRoles.aguacate_retail_cashier) {
      this.tasas = await this.ratesAc.getAllRates();
      await this.getDataCRX();
      this.completedCount = await this.transactionService.getCompletedCount();
      this.pendingCount = await this.transactionService.getPendingCount();

    }

    this.rates = this.currentValues;

  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isNotNullOrEmpty(object: Object) {
    return object && Object.keys(object).length > 0;
  }

  formatDate(date: any) {
    const months = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];

    return `${day} ${month}`;
  }

  async getDataCRX() {
    const response = await this.agCRXTService.getValuesRateCrx();
    if (response && typeof response === 'object' && 'data' in response) {
      this.dataCRXT = response.data;
      this.tipoTasa = this.dataCRXT.typeRate;
    } else {
      this.dataCRXT = null;
    }
  }

  async setRates() {
    const data = {
      tasaCLP: this.dataCRXT.clp,
      porcentajeComision: this.dataCRXT.comision,
      tasaCRXT: this.dataCRXT.rateUsd,
      feeCRX: this.dataCRXT.feeCRX,
    }
    this.dialog.open(SetValuesAgComponent, { data }).afterClosed().subscribe(async res => {
      if (res && res === true) {
        await this.loadData();
      }
    })
  }

  async copiarTasa() {
    let message = await this.setMessage();
    this.clipboard.copy(message);
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: 'success',
      title: 'Tasas Manuales copiadas en el portapapeles'
    });
    //console.log(message);
  }

  async setMessage() {
    const webRates = [this.tasas[5], this.tasas[4], this.tasas[3]];
    const whatsappRates = [this.tasas[2], this.tasas[1], this.tasas[0]];
    const formatRates = (rates: any[]) =>
      rates.map(this.calculateData).join("\n");

    return `💻 TASA WEB: pagos realizados en nuestra página web www.aguacatecambios.com
${formatRates(webRates)}

🥑 TASA VERDE: pagos reportados por WhatsApp
${formatRates(whatsappRates)}

Envía dinero de forma confiable a Venezuela con profesionales 💰💼🇻🇪 www.aguacatecambios.com`;
  }

  calculateData(data: any) {
    const rango = parseInt(data.rango.split("-")[0], 10);
    const tasa = parseFloat(data.tasa);
    const antes = (tasa * 1000).toFixed(3);
    const bs = (rango * tasa).toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    return `${data.tasa} (antes ${antes}) pagos a partir de $${rango.toLocaleString("de-DE")} pesos o ${bs} bs`;
  }

  redirect(route: string) {
    this.router.navigate([route]);
  }

  async onTipoTasaChange() {
    if (this.tipoTasa === 'AUTOMATICO') {
      await this.actualizarAuto();
    } else {
      await this.actualizarManual();
    }
  }

  async actualizarAuto() {
    try {
      const response = await this.agCRXTService.setCalculateValuesAuto();
      console.log('Datos obtenidos:', response);
      await this.loadData();

    } catch (error) {
      console.error('Error llamando al servicio:', error);
    }
  }

  async actualizarManual() {
    try {
      const response = await this.agCRXTService.setChangeValueManual();
      console.log('Datos obtenidos:', response);
      await this.loadData();

    } catch (error) {
      console.error('Error llamando al servicio:', error);
    }
  }

  enviarDinero() {
    Swal.fire({
      title: 'Enviar dinero',
      text: 'Selecciona el tipo de envío',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonColor: '#1e5a3f',
      denyButtonColor: '#1e5a3f',
      confirmButtonText: 'Uno a uno',
      denyButtonText: `Múltiple`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['wallet/client/single']);
      } else if (result.isDenied) {
        this.router.navigate(['wallet/client/multiple']);
      }
    })
  }
}
