<mat-toolbar color="primary" class="h-20">
  <button mat-icon-button (click)="sidenav.toggle()" matTooltip="Menu">
    <mat-icon>menu</mat-icon>
  </button>
  <img class="w-16" src="assets/img/aguacate-icon.png" routerLink="/retail/home">
  <span class="toolbar-item-spacer"></span>
  <div class="toolbar-detail flex flex-col items-end  align-middle">
    <p class="font-medium">Bienvenido </p>
    <span> {{userName}} </span>
  </div>

  <button class="icon-my-account" mat-icon-button matTooltip="Mi Cuenta" [matMenuTriggerFor]="userAccountMenu">
    <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #userAccountMenu [overlapTrigger]="false" yPosition="below">
    <button mat-menu-item routerLink="client/user-profile" [disabled]="!isEnabledUser"
      *ngIf="userRole === rolesEnum.aguacate_client">
      <mat-icon>person</mat-icon><span>Mi cuenta</span>
    </button>
    <!-- <button mat-menu-item routerLink="#">
            <mat-icon>settings</mat-icon><span>Configuración</span>
        </button> -->
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="signOut()">
      <mat-icon>exit_to_app</mat-icon>Cerrar sesión
    </button>
  </mat-menu>
</mat-toolbar>

<mat-sidenav-container class="app-container">
  <mat-sidenav #sidenav mode="push" opened="false" color="primary" [class.mat-elevation-z4]="true" class="app-sidenav">
    <mat-nav-list>
      <mat-list-item routerLink="/retail/home" class="menu-element" (click)="closeSidenav(sidenav)">
        <span class="pl-5 flex flex-row justify-start gap-8"> <span class="icon-home"></span> Inicio</span>
      </mat-list-item>
      <!-- Menu usuario -->
      <ng-container *ngIf="userRole=== rolesEnum.aguacate_retail_client">

        <mat-list-item routerLink="/retail/recipients" class="menu-element" (click)="closeSidenav(sidenav)">
          <span class="pl-5 flex flex-row justify-start gap-8"><span><mat-icon class="h-5 w-5"
                style="font-size: 20px;">assignment_ind</mat-icon></span> Beneficiarios Fiat</span>
        </mat-list-item>

      </ng-container>
      <!-- Menu operario -->
      <ng-container *ngIf="userRole=== rolesEnum.aguacate_retail_cashier">
        <!-- <mat-list-item routerLink="/wallet/admin/dashboard-operation" class="menu-element"
          (click)="closeSidenav(sidenav)">
          <mat-icon>bar_chart_4_bars</mat-icon> <span class="pl-5">Dashboard de operaciones</span>
        </mat-list-item> -->
        <mat-list-item routerLink="/retail/transaction-cashier" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>attach_money</mat-icon> <span class="pl-5">Abonos/Debitos</span>
        </mat-list-item>
        <mat-list-item routerLink="/retail/balance-cajero" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>account_balance_wallet</mat-icon> <span class="pl-5">Balance cajero</span>
        </mat-list-item>
        <mat-list-item routerLink="/retail/cash-closing-report" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>price_check</mat-icon> <span class="pl-5 text-center">Reporte cierre de caja</span>
        </mat-list-item>
        <mat-list-item routerLink="/retail/user-balance" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>account_balance_wallet</mat-icon> <span class="pl-5">Movimientos de usuarios</span>
        </mat-list-item>
        <mat-list-item routerLink="/retail/status-cajero" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>request_quote</mat-icon> <span class="pl-5">Estatus cajero</span>
        </mat-list-item>
        <mat-list-item routerLink="/retail/new-user" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>person_add</mat-icon> <span class="pl-5">Crear Usuario</span>
        </mat-list-item>
        <mat-list-item routerLink="/retail/compliance" class="menu-element" (click)="closeSidenav(sidenav)">
          <mat-icon>policy</mat-icon> <span class="pl-5 text-center">Compliance</span>
        </mat-list-item>
        <mat-expansion-panel [class.mat-elevation-z0]="true" class="menu-element -ml-2">
          <mat-expansion-panel-header>
            <mat-icon>currency_exchange</mat-icon> <span class="pl-5">Transacciones VES</span>
          </mat-expansion-panel-header>
          <mat-nav-list>
            <mat-list-item routerLink="/retail/transactions" class="menu-element" (click)="closeSidenav(sidenav)">
              <mat-icon>currency_exchange</mat-icon> <span class="pl-5">Lista de transacciones VES</span>
            </mat-list-item>
            <mat-list-item [routerLink]="['/retail/pending-transactions-binance']"
              [queryParams]="{ bankType: 'BINANCE'}" class="menu-element" (click)="closeSidenav(sidenav)">
              <mat-icon>currency_bitcoin</mat-icon> <span class="pl-5">Transacciones pendientes Binance</span>
            </mat-list-item>
            <mat-list-item routerLink="/retail/pending-transactions" class="menu-element"
              (click)="closeSidenav(sidenav)">
              <mat-icon>schedule</mat-icon> <span class="pl-5">Transacciones pendientes</span>
            </mat-list-item>
            <mat-list-item routerLink="/retail/completed-ves-transactions" class="menu-element"
              (click)="closeSidenav(sidenav)">
              <mat-icon>schedule</mat-icon> <span class="pl-5">Transacciones VES completadas</span>
            </mat-list-item>
            <mat-list-item routerLink="/retail/crixto" class="menu-element"
              (click)="closeSidenav(sidenav)">
              <mat-icon>schedule</mat-icon> <span class="pl-5">Transacciones Crixto</span>
            </mat-list-item>
          </mat-nav-list>
        </mat-expansion-panel>
        <mat-list-item routerLink="working-accounts" class="menu-element" (click)="closeSidenav(sidenav)">
          
          <mat-icon>work</mat-icon> <span class="pl-5">Cuentas de trabajo</span>
        </mat-list-item>
        <mat-expansion-panel [class.mat-elevation-z0]="true" class="menu-element -ml-2">
          <mat-expansion-panel-header>
            <mat-icon>settings</mat-icon> <span class="pl-5">Configuración</span>
          </mat-expansion-panel-header>
          <mat-nav-list>
            <mat-list-item routerLink="/retail/rates" class="menu-element" (click)="closeSidenav(sidenav)">
              <mat-icon>toggle_off</mat-icon> <span class="pl-5">Parámetros tasa bolivares</span>
            </mat-list-item>
            <mat-list-item routerLink="/retail/operation-hours" class="menu-element" (click)="closeSidenav(sidenav)">
              <mat-icon>toggle_off</mat-icon> <span class="pl-5">Horarios de operación</span>
            </mat-list-item>
          </mat-nav-list>
        </mat-expansion-panel>


      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">

    <div class="app-sidenav-content">
      <router-outlet></router-outlet>
      <div class="fab-container" *ngIf="userRole=== rolesEnum.aguacate_retail_client">
        <button color="primary" mat-fab class="fab-toggler" (click)="redirectToExternalLink()">
          <i class="material-icons">contact_support</i>
        </button>
      </div>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
