<div class="m-4 w-full  overflow-x-auto ">
  <h1 class="m-4 text-2xl font-bold text-center">{{ isBinance ? 'Transacciones pendientes Binance' : 'Transacciones
    pendientes ' }} </h1>

  <div class="flex flex-row gap-5 align-middle mb-4 justify-center flex-wrap" style="max-width: 800px; margin: 0 auto;">
    <mat-form-field class="p-4">

      <input matInput type="text" (keyup)="doFilter($event)" placeholder="Filtrar datos">
      <mat-icon matPrefix class="my-icon">search</mat-icon>
    </mat-form-field>
    <button class="ml-4" mat-raised-button color="primary" aria-label="guardar seleccion" title="guardar seleccion"
      (click)='guardarSeleccion()' *ngIf="!isObjectEmpty(cambios)">
      <mat-icon>check</mat-icon>
      Guardar selección
    </button>
  </div>
  <div class="flex flex-row gap-5 align-middle mb-4 justify-center flex-wrap" style="max-width: 800px; margin: 0 auto;">
    <button class="ml-4" mat-raised-button color="primary" aria-label="guardar seleccion" title="guardar seleccion"
      (click)='refreshRates()'>
      Refrescar Tasas
    </button>
  </div>


  <div class="flex overflow-x-auto">
    <table mat-table [dataSource]="dataSource" class="m-4" *ngIf="dataSource">
      <ng-container matColumnDef="seleccion">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions"
          style="background-color: #CEE9C2;text-align:center">Seleccionar</th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox (change)="change($event, element.id)" [disabled]=" checkEmailOperador(element.emailOperador)"
            [checked]="element.emailOperador ? true : false" class="example-margin"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
        <th mat-header-cell *matHeaderCellDef style="background-color: #CEE9C2;text-align:center">{{column.header}}</th>
        <td mat-cell *matCellDef="let row">{{column.cell(row)}}</td>
      </ng-container>
      <ng-container matColumnDef="precioVenta">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions"
          style="background-color: #CEE9C2; text-align:center">Precio de venta</th>
        <td mat-cell *matCellDef="let element">
          {{currentRate(element.cuentaBeneficiario.banco.nombre) | number : '1.2-3'}}
        </td>
      </ng-container>
      <ng-container matColumnDef="pago">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions"
          style="background-color: #CEE9C2; text-align:center">Precio de compra</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex flex-row gap-3 align-middle items-center justify-center">
            <mat-form-field style="    margin-top: 20px;">
              <mat-label>Precio compra</mat-label>
              <input matInput matInputCommified appOnlynumber [id]="'valorCompra'+ element.id"
                [(ngModel)]="element.precio_compra" [disabled]=" disableButton(element.emailOperador)">
              <span matTextPrefix>$&nbsp;</span>
            </mat-form-field>
            <button mat-raised-button color="primary" aria-label="Guardar" title="guardar"
              [disabled]=" disableButton(element.emailOperador)"
              (click)="setPurchaseValue(element.id, element.id)"><mat-icon>save</mat-icon></button>
          </div>

        </td>
      </ng-container>
      <ng-container matColumnDef="totalMonedaDestino">
        <th mat-header-cell *matHeaderCellDef style="background-color: #CEE9C2; text-align:center; min-width: 180px;">
          Monto destino
        </th>
        <td mat-cell *matCellDef="let element" class="min-w-[200px]">
          <div class="flex items-center justify-between w-full">
            <span class="flex-grow">
              {{element.totalMonedaDestino | currency:'$':'symbol':'1.0-2'}} {{element.monedaDestino}}
            </span>
            <button mat-icon-button [cdkCopyToClipboard]="formatCurrencyForCopy(element.totalMonedaDestino)"
              color="primary" aria-label="Copiar Monto destino" title="copiar"
              class="w-10 p-0 flex items-center justify-center">
              <mat-icon class="text-base">content_copy</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions"
          style="background-color: #CEE9C2; text-align:center">Acciones</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex flex-row gap-3">
            <button mat-raised-button color="primary" aria-label="copiar" title="copiar"
              [disabled]=" disableButton(element.emailOperador)" (click)="copy(element)">
              <mat-icon>content_copy</mat-icon>
            </button>
            <button class="content-center text-center" mat-raised-button color="primary" aria-label="Pagar"
              title="Pagar"
              [disabled]=" disableButton(element.emailOperador) || ((!element.precio_compra || element.precio_compra === '0') && isBinance)"
              (click)="openSendTransactionModal(element)">
              <mat-icon>done
              </mat-icon>
            </button>
            <button mat-raised-button aria-label="Editar" title="Editar"
              [disabled]=" disableButton(element.emailOperador)" (click)="openEditTransactionModal(element)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-raised-button color="warn" aria-label="Rechazar" title="Rechazar"
              [disabled]=" disableButton(element.emailOperador)" (click)="openRejectTransactionModal(element)">
              <mat-icon>close</mat-icon>
            </button>
            <button mat-raised-button aria-label="Reasignar" title="Reasignar" [disabled]=" disableButton(element.emailOperador)"
              (click)="reasign(element)">
              <mat-icon>edit_note</mat-icon>
            </button>

          </div>

        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>