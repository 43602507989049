    <div class="p-4 flex flex-col md:p-14 gap-[1rem] mt-2">
      <ng-container *ngIf="userRole === userEnum.aguacate_retail_client">
        <ng-container *ngIf="estadoValidacion === 2">
          <div>
            <h1 class="ml-4 text-lg ">Hola, <span class="font-bold">{{user.nombre}}</span></h1>
          </div>
          <div class="p-3 md:p-6 bg-white rounded-lg shadow-md">
            <h2 class="text-2xl font-bold text-center mb-2">Tasas del dia</h2>
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
              <div class="bg-white p-6 rounded-lg shadow-lg" *ngFor="let rate of rates">
                <p class="text-aguacate-green whitespace-nowrap">
                  <b>
                    {{ rate.rango.split('-')[0] | currency:'USD':'$':'1.0-0' }} CLP - {{ rate.rango.split('-')[1] | currency:'USD':'$':'1.0-0' }} CLP
                  </b>
                </p>
                <p class="text-sm">{{ rate.descripcion }}</p>

                <p class="text-lg font-bold text-aguacate-green">Tasa: {{ rate.tasa | number:'1.5-5' }}</p>
              </div>
            </div>
          </div>
          <h1 class="text-2xl font-bold mx-4 mt-4 mb-4">¿Qué deseas realizar hoy?</h1>
          <div class="w-full md:w-2/4 flex flex-row md:self-start gap-10 justify-start mb-4">
            <div class="flex flex-col items-center justify-between aguacate-icons" routerLink="/retail/exchange">
              <img src="./assets/icons/send_fiat.svg" class="w-[40px]">
              <p class="text-sm font-bold mt-2">Enviar Remesa</p>
            </div>

          </div>
          <hr>
          <app-transactions></app-transactions>
        </ng-container>
        <ng-container *ngIf="estadoValidacion===0">
          <button mat-raised-button color="primary" class="w-full md:w-1/3 py-5" routerLink="/retail/user-compliance">Verifica tu cuenta</button>
          <span class="px-6 py-4 bg-aguacate-green/25 rounded-lg  font-semibold">Para procesar tus envíos es necesario que nos
            facilites algunos datos personales, esto con el objetivo de hacer tus envíos mas seguros y verificar tu identidad.
          </span>
        </ng-container>
        <ng-container *ngIf="estadoValidacion===1">
          <span class="px-6 py-4 bg-aguacate-green/25 rounded-lg  font-semibold">Tu información está siendo validada por nuestro equipo, se te notificará a tu correo electrónico cuando el proceso finalice
          </span>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="userRole === userEnum.aguacate_retail_cashier">
        <div class="p-4 flex flex-col md:px-14 md:py-8">
          <h1 class="ml-4 text-lg ">¡Bienvenido(a) al portal de transacciones!</h1>
        </div>
        <div class="flex flex-wrap justify-between">
          <div class="w-full sm:w-1/2 lg:w-1/3 overflow-auto place-content-center min-w-[340px] max-w-[640px] max-h-[380px] shadow-md">
              <h1 class="text-2xl font-bold mx-4 text-center min-h-[40px] md:min-h-[50px] mb-1">
                  Operaciones por procesar
              </h1>
              <div class="p-2">
                  <div class="w-full flex flex-row gap-2 flex-wrap text-center place-content-center">
                      <div class="clickable-card rounded-[25px] p-3 border-2 border-aguacate-green"
                          (click)="redirect('/retail/transactions')">
                          <h2 class="text-lg font-bold" [ngClass]="{
                              'blinking-text': this.pendingCount && this.pendingCount?.AC>30,
                              'blinking-text2': this.pendingCount && this.pendingCount?.AC<=30&&this.pendingCount?.AC>15
                          }">
                              AC bolivares
                          </h2>
                          <p [ngClass]="{
                              'blinking-text': this.pendingCount && this.pendingCount?.AC>30,
                              'blinking-text2': this.pendingCount && this.pendingCount?.AC<=30&&this.pendingCount?.AC>15
                          }">
                              {{this.pendingCount ? this.pendingCount?.AC : 'Cargando'}}
                          </p>
                          <h4 class="italic">Pendientes</h4>
                      </div>
                  </div>
              </div>
          </div>

          <div class="w-full sm:w-1/2 lg:w-1/3 overflow-auto place-content-center min-w-[340px] max-w-[640px] max-h-[380px] shadow-md">
              <h1 class="text-2xl font-bold mx-4 text-center min-h-[40px] md:min-h-[50px] mb-2">
                  Operaciones completadas hoy {{formatDate(todayDate) }}
              </h1>
              <div class="p-2">
                  <div class="w-full flex flex-row gap-2 flex-wrap text-center place-content-center">
                      <div class="rounded-[25px] border-2 border-aguacate-green p-3 w-[150px]"
                          (click)="redirect('/retail/completed-ves-transactions')">
                          <h2 class="text-lg font-bold">AC bolivares</h2>
                          <p>{{this.completedCount?.currentDay ? this.completedCount?.currentDay.AC : 'Cargando'}}</p>
                          <h4 class="italic">Completadas</h4>
                      </div>
                  </div>
              </div>
          </div>

          <div class="w-full sm:w-1/2 lg:w-1/3 overflow-auto place-content-center min-w-[340px] max-w-[640px] max-h-[380px] shadow-md">
              <h1 class="text-2xl font-bold mx-4 text-center min-h-[40px] md:min-h-[50px] mb-2">
                  Operaciones completadas ayer {{formatDate(yesterdayDate) }}
              </h1>
              <div class="p-2">
                  <div class="w-full flex flex-row gap-2 flex-wrap text-center place-content-center">
                      <div class="rounded-[25px] border-2 border-aguacate-green p-3 w-[150px]"
                          (click)="redirect('/retail/completed-ves-transactions')">
                          <h2 class="text-lg font-bold">AC bolivares</h2>
                          <p>{{this.completedCount?.previousDay ? this.completedCount?.previousDay.AC : 'Cargando'}}</p>
                          <h4 class="italic">Completadas</h4>
                      </div>
                  </div>
              </div>
          </div>
      </div>
        <div class="p-3 md:p-6 bg-white rounded-lg shadow-md" *ngIf="dataCRXT">
          <h2 class="text-2xl font-bold text-center mb-2">💻 Tasa web del día</h2>
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
            <div class="bg-white p-6 rounded-lg shadow-lg text-center" *ngFor="let tasas of tasas | slice:3:6">
              <p class="text-aguacate-green whitespace-nowrap">
                <b>
                  {{ tasas.rango.split('-')[0] | currency:'USD':'$':'1.0-0' }} CLP - {{ tasas.rango.split('-')[1] | currency:'USD':'$':'1.0-0' }} CLP
                </b>
              </p>
              <p class="text-sm">{{ tasas.descripcion }}</p>
              <p class="text-lg font-bold text-aguacate-green">Tasa: {{ tasas.tasa | number:'1.5-5' }}</p>
            </div>
          </div>
        </div>

        <div class="p-3 md:p-6 bg-white rounded-lg shadow-md" *ngIf="dataCRXT">
          <h2 class="text-2xl font-bold text-center mb-2">🥑 Tasa verde del día</h2>
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
            <div class="bg-white p-6 rounded-lg shadow-lg text-center" *ngFor="let tasas of tasas | slice:0:3">
              <p class="text-aguacate-green whitespace-nowrap">
                <b>
                  {{ tasas.rango.split('-')[0] | currency:'USD':'$':'1.0-0' }} CLP - {{ tasas.rango.split('-')[1] | currency:'USD':'$':'1.0-0' }} CLP
                </b>
              </p>
              <p class="text-sm">{{ tasas.descripcion }}</p>
              <p class="text-lg font-bold text-aguacate-green">Tasa: {{ tasas.tasa | number:'1.5-5' }}</p>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap justify-between">
          <div class="w-full sm:w-1/2 lg:w-1/3 overflow-auto min-w-[340px] max-w-[640px] max-h-[380px] shadow-md mx-auto">
            <h1 class="text-2xl font-bold mx-4 text-center min-h-[40px] md:min-h-[50px] mb-1">
              Valores actuales
            </h1>
            <div class="p-2 flex flex-col items-center">
              <div class="flex flex-col items-start text-center">
                <!-- Si dataCRXT es null o undefined, mostrar mensaje de error -->
                <h2 *ngIf="!dataCRXT" class="text-lg font-bold">
                  ⏳ Cargando información...
                </h2>


                <!-- Si dataCRXT tiene valor, mostrar la información -->
                <ng-container *ngIf="dataCRXT">
                  <h2 class="text-lg font-bold" [ngClass]="{'text-aguacate-green': dataCRXT}">
                    Tasa USD-CLP: {{ (dataCRXT?.clp ? (dataCRXT.clp | number : "1.2-2") : 'Cargando') }}
                  </h2>
                  <h2 class="text-lg font-bold" [ngClass]="{'text-aguacate-green': dataCRXT}">
                    Tasa CRIXTO: {{ dataCRXT?.rateUsd !== undefined ? (dataCRXT.rateUsd | number : "1.2-5") : 'Cargando' }}
                  </h2>
                </ng-container>
              </div>

              <div class="flex flex-col items-start text-center">
                <!-- Si el balance es 0 o no existe, mostrar mensaje de alerta -->
                <h3 *ngIf="dataCRXT && !dataCRXT.balance" class="text-lg text-red-600 font-bold">
                  ⚠️ Se agota el saldo en CRIXTO, informar a administradores.
                </h3>
              </div>

              <div class="flex justify-center space-x-4 mt-4" *ngIf="dataCRXT">
                <button
                  (click)="setRates()"
                  type="button"
                  class="ml-14 mb-4 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Actualizar tasas
                </button>

                <button
                  (click)="copiarTasa()"
                  type="button"
                  class="mb-4 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Copiar tasa
                </button>
              </div>
              <div class="flex flex-wrap justify-between" *ngIf="dataCRXT">
                <mat-button-toggle-group [(ngModel)]="tipoTasa" (change)="onTipoTasaChange()" aria-label="Tipo de Tasa">
                  <mat-button-toggle value="AUTOMATICO">Automática</mat-button-toggle>
                  <mat-button-toggle value="MANUAL">Manual</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>

          </div>
        </div>



      </ng-container>
    </div>


