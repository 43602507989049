import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { CuentaTrabajo } from 'src/app/dashboard/interfaces/cuenta-trabajo.interface';
import { Transaccion } from 'src/app/dashboard/interfaces/transaccion.interface';
import { WorkingAccountService } from 'src/app/dashboard/services/working-account.service';
import { columns, displayedColumns } from '../table-transactions-columns';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { TransaccionService } from 'src/app/dashboard/services/transaccion.service';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { AssignTransactionsDTO } from 'src/app/dashboard/interfaces/assign-transactions.dto';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-assign-transaction',
  templateUrl: './assign-transaction.component.html',
  styleUrls: ['./assign-transaction.component.sass']
})
export class AssignTransactionComponent {
  workingAccountList: CuentaTrabajo[] = [];
  selectedWorkingAccount: CuentaTrabajo | undefined = undefined;
  transacciones: Transaccion[] = [];
  seleccionados: number = 0;
  dataSource: MatTableDataSource<any> | undefined;
  subscription: Subscription = new Subscription();
  columns = columns;
  countryList = ['VE'];
  type = '';
  displayedColumns: string[] = [
    'id',
    'userId',
    'cuentaBeneficiarioId',
    'ruta',
    'bancoDestino',
    'totalMonedaOrigen',
    'totalMonedaDestino',
    'acciones',
  ];

  constructor(
    private auth: CognitoService,
    private readonly transactionService: TransaccionService,
    private spinner: SpinnerService, private workingAccountService: WorkingAccountService, public dialogRef: MatDialogRef<AssignTransactionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.getWorkingAccounts();
    this.transacciones = data.selectedTransactions || [];
    this.dataSource = new MatTableDataSource(this.transacciones);
  }

  remover(indice: number) {
    if (this.dataSource) {
      this.dataSource.data = this.dataSource.data.filter((x, i) => i !== indice);

    }
  }

  async assignTransaction() {
    if (!this.selectedWorkingAccount) return;
    let transactionsID = this.dataSource?.data.map((x) => x.id) || [];
    if (this.data.trxId && !transactionsID.includes(this.data.trxId)) {
      transactionsID.push(this.data.trxId);
    }
    const cognitoUser = await this.auth.getUser();
    const email = cognitoUser.attributes.email;
    const payload: AssignTransactionsDTO = {
      operatorEmail: email,
      workingAccountID: this.selectedWorkingAccount.cuenta_trabajo_id,
      transactions: transactionsID
    }
    const spinnerRef = this.spinner.start();
    try {
      const response = await this.transactionService.asignTransactions(payload);
      Swal.fire({
        icon: 'success',
        title: 'Transacciones asignadas',
        text: 'las transacciones se han asignado exitosamente',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#1e5a3f',

      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al intentar asignar las transacciones',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#1e5a3f',

      });
    }
    this.spinner.stop(spinnerRef);
    this.dialogRef.close(true);

    //
  }


  onNoClick() {
    this.dialogRef.close()
  }
  async getWorkingAccounts() {
    this.workingAccountList = await this.workingAccountService.getWorkingAccounts(this.countryList);
  }
}
