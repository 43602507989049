import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { BinanceService } from 'src/app/dashboard/services/binance.service';
import { RatesAcCRXTService } from 'src/app/retail/services/ac-crxt.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-set-values',
  templateUrl: './set-values.component.html',
  styleUrls: ['./set-values.component.css']
})
export class SetValuesAgComponent {
  formCuenta: FormGroup | undefined;

  subscriptions: Subscription = new Subscription();
  esUpdate = false;
  email: string = '';
  constructor(
    private fb: FormBuilder,
    private auth: CognitoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private agCRXTService: RatesAcCRXTService,
    private dialogRef: MatDialogRef<SetValuesAgComponent>,
    private spinner: SpinnerService,
  ) {
    this.initFormCuenta();
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async initFormCuenta() {
    const cognitoUser = await this.auth.getUser();
    this.email = cognitoUser.attributes['email'];
    this.formCuenta = this.fb.group({
      tasaCLP: new FormControl(this.data.tasaCLP, [Validators.required, Validators.min(0)]),
      porcentajeComision: new FormControl(this.data.porcentajeComision, [Validators.required, Validators.min(0.7)]),
      tasaCRXT: new FormControl(this.data.tasaCRXT, [Validators.required, Validators.min(0.7)]),
      feeCRX: new FormControl(this.data.feeCRX),
    });
    console.log(this.formCuenta)
  }

  async actualizarTasa() {
    if (this.formCuenta?.invalid) return;

    const tasas = this.formCuenta?.value;
    const payload = {
      tasaCLP: tasas.tasaCLP,
      comision: tasas.porcentajeComision,
      tasaCRXT: tasas.tasaCRXT,
      feeCRX: tasas.feeCRX,
      email: this.email
    };

    let ref = this.spinner.start();

    try {
      await this.agCRXTService.postCalculateValuesManual(payload);
      this.spinner.stop(ref);

      Swal.fire('Tasa actualizada', 'Se han actualizado los valores', 'success').then(() => {
        this.dialogRef.close(true);
      });
    } catch (error) {
      this.spinner.stop(ref);

      Swal.fire('Error', 'No se ha podido actualizar la tasa', 'error').then(() => {
        this.dialogRef.close(false);
      });
    }
  }



}
