<div class="p-6 space-y-4 md:space-y-6 sm:p-8">
  <div *ngIf="!viewForm">
    <div class="grid grid-cols-1 text-center">
      <mat-label class="font-bold block mb-2">
        ¿Qué tipo de beneficiario deseas agregar?
      </mat-label>
      <mat-radio-group
        class="flex justify-center gap-4"
        [value]="tipoBeneficiario"
        (change)="onTipoBeneficiarioChange($event.value)"
      >
        <mat-radio-button [value]="0">Persona</mat-radio-button>
        <mat-radio-button [value]="1">Empresa</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="grid grid-cols-1 text-center mt-4">
      <mat-label class="font-bold block mb-2"
        >Elige tu forma de envío</mat-label
      >
      <mat-radio-group
        class="flex justify-center gap-4"
        [value]="formaEnvio"
        (change)="onFormaEnvioChange($event.value)"
      >
        <mat-radio-button [value]="0">Transferencia Bancaria</mat-radio-button>
        <mat-radio-button [value]="1">Pago Móvil</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="container flex flex-row gap-4 items-center justify-center mt-4">
      <button mat-raised-button type="button" (click)="cancel()" color="warn">
        Cancelar
      </button>
      <button
        mat-raised-button
        type="button"
        (click)="generateForm()"
        color="primary"
      >
        Siguiente
      </button>
    </div>
  </div>

  <!-- FORM ACOUNT -->
  <div *ngIf="viewForm">
    <div class="container flex flex-col items-center mb-4">
      <h1 class="text-xl font-bold">
        {{ esUpdate ? "Actualizar cuenta" : "Registrar cuenta" }}
      </h1>
    </div>
    <div class="container flex flex-col items-center">
      <h2 class="text-xl">
        Tipo de beneficiario:
        {{ tipoBeneficiario === 0 ? "Persona" : "Empresa" }}
      </h2>
    </div>
    <div class="container flex flex-col items-center mb-4">
      <h2 class="text-xl">
        Forma de envío:
        {{ formaEnvio === 0 ? "Transferencia Bancaria" : "Pago Movil" }}
      </h2>
    </div>

    <form
      (ngSubmit)="guardarBeneficiario()"
      *ngIf="formCuenta"
      class="space-y-4 md:space-y-6"
      [formGroup]="formCuenta"
    >
      <!-- COUNTRY SECTION-->
      <div class="grid grid-cols-1 gap-4">
        <mat-form-field>
          <mat-label>País</mat-label>
          <mat-select formControlName="pais" [disabled]="true">
            <mat-option
              *ngFor="let pais of paisesActivos"
              [value]="pais.codigoPais"
            >
              {{ pais.nombre | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <h1 class="text-large font-bold">Formulario beneficiario</h1>
      <!-- DATA NAME SECTION PERSON-->
      <div
        *ngIf="tipoBeneficiario === 0"
        class="grid grid-cols-1 md:grid-cols-2 gap-4"
      >
        <mat-form-field>
          <mat-label>Nombre</mat-label>
          <input
            type="text"
            matInput
            formControlName="nombre"
            placeholder="Ingresa tu nombre"
            (keypress)="onlyLetters($event)"
            (input)="toUpperCase($event)"
          />
          <mat-error *ngIf="getErrorMessageName('nombre')">
            {{ getErrorMessageName("nombre") }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Apellidos</mat-label>
          <input
            type="text"
            matInput
            formControlName="apellidos"
            placeholder="Ingresa tu apellido"
            (keypress)="onlyLetters($event)"
            (input)="toUpperCase($event)"
          />
          <mat-error *ngIf="getErrorMessageName('apellidos')">
            {{ getErrorMessageName("apellidos") }}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- DATA NAME SECTION COMPANY-->
      <div *ngIf="tipoBeneficiario === 1" class="grid grid-cols-1 gap-4">
        <mat-form-field>
          <mat-label>Razón Social</mat-label>
          <input
            type="text"
            matInput
            formControlName="nombre"
            placeholder="Ingresa tu Razón Social"
            (keypress)="fieldValueCompany($event)"
            (input)="toUpperCase($event)"
          />
          <mat-error *ngIf="getErrorMessageName('nombre')">
            {{ getErrorMessageName("nombre") }}
          </mat-error>
        </mat-form-field>
      </div>

      <!-- DATA ID SECTION -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <mat-form-field>
          <mat-label>Tipo documento</mat-label>
          <mat-select
            required
            formControlName="tipoID"
            [(ngModel)]="selectedTipoID"
            (selectionChange)="onTipoIDChange()"
          >
            <mat-option
              *ngFor="
                let tipo of tipoBeneficiario === 0 ? tiposID : tiposIDJuridica
              "
              [value]="tipo"
            >
              {{ tipo }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="flex items-center space-x-2">
          <span
            *ngIf="selectedTipoID"
            class="px-3 py-2 bg-gray-200 text-gray-700 rounded-md"
          >
            {{ documentPrefix }}
          </span>

          <mat-form-field class="flex-1">
            <mat-label>Número identificación:</mat-label>
            <input
              matInput
              formControlName="numeroID"
              placeholder="Ingresa tu documento"
              (keypress)="onlyNumbersID($event)"
            />
            <mat-error *ngIf="getNumeroIdErrorMessage('numeroID')">
              {{ getNumeroIdErrorMessage("numeroID") }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- DATA BANK ACCOUNT SECTION-->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <mat-form-field>
          <mat-label>Banco</mat-label>
          <mat-select
            required
            formControlName="banco"
            (selectionChange)="onBancoChange($event.value)"
          >
            <mat-option *ngFor="let banco of bancos" [value]="banco">
              {{ banco.nombre | titlecase }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="this.formCuenta.get('banco')!.hasError('required')">
            Seleccione un banco
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-span-1" *ngIf="!isPersonMovilPay">
          <mat-label>Tipo de cuenta</mat-label>
          <mat-select required formControlName="tipoCuenta">
            <mat-option
              *ngFor="let tipo of tiposCuentas"
              [value]="tipo.tipoCuentaID"
            >
              {{ tipo.nombre | titlecase }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="this.formCuenta.get('tipoCuenta')!.hasError('required')"
          >
            Seleccione un tipo de cuenta
          </mat-error>
        </mat-form-field>
      </div>

      <!-- DATA NUMBER BANK ACCOUNT SECTION-->
      <div class="grid grid-cols-1 gap-4">
        <div class="flex items-stretch space-x-2">
          <span
            *ngIf="isPersonMovilPay"
            class="flex items-center px-3 bg-gray-200 text-gray-700 rounded-md"
          >
            +58
          </span>

          <mat-form-field class="flex-1">
            <mat-label>{{
              isPersonMovilPay ? "Número de Teléfono" : "Número de Cuenta"
            }}</mat-label>

            <input
              *ngIf="!isPersonMovilPay"
              type="text"
              matInput
              formControlName="numeroCuenta"
              (input)="onNumeroCuentaInput($event)"
              (keypress)="onlyNumbers($event)"
              placeholder="Ej. 00000 0000 00000 00000"
            />
            <input
              *ngIf="isPersonMovilPay"
              type="text"
              matInput
              formControlName="numeroCuenta"
              (keypress)="onlyNumbersID($event)"
              placeholder="Ej. 4141234567"
            />

            <mat-hint matSuffix class="mr-4 md:mr-10 text-xs md:text-base">
              {{ formCuenta.get("numeroCuenta")?.value.length }} /
              {{ isPersonMovilPay ? 10 : 20 }}
            </mat-hint>

            <mat-error *ngIf="getErrorMessageAccountNumber('numeroCuenta')">
              {{ getErrorMessageAccountNumber("numeroCuenta") }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- BUTTONS -->
      <div class="container flex flex-row gap-4 items-center justify-center">
        <button mat-raised-button type="button" (click)="cancel()" color="warn">
          Cancelar
        </button>
        <button
          mat-raised-button
          type="button"
          (click)="back()"
          color="accent"
          *ngIf="!esUpdate"
        >
          Atrás
        </button>
        <button mat-raised-button type="submit" color="primary">Guardar</button>
      </div>
    </form>
  </div>
</div>
