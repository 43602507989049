<div class="p-5">
    <h1 class="text-xl text-center mt-8">Tasas del dia.</h1>
    <div class="flex overflow-x-auto">
        <table mat-table [dataSource]="dataSource" class="my-4 w-5/6 m-h-[60%]" *ngIf="dataSource">

          <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
            <th mat-header-cell *matHeaderCellDef style="background-color: #CEE9C2; text-align:center">{{column.header}}</th>
            <td mat-cell *matCellDef="let row" style="text-align:center">
              <ng-container *ngIf="row.isEditing && (column.columnDef === 'tasa')">
                <input [(ngModel)]="row[column.columnDef]" type="text" matInput />
              </ng-container>
              <ng-container *ngIf="!row.isEditing || (column.columnDef !== 'tasa')">
                {{ column.cell(row) }}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="background-color: #CEE9C2; text-align:center">Acciones</th>
            <td mat-cell *matCellDef="let row" style="text-align:center">
              <button *ngIf="!row.isEditing" mat-button (click)="editRow(row)">Editar</button>
              <button *ngIf="row.isEditing" mat-button (click)="saveRow(row)">Guardar</button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>

    <button mat-raised-button color="primary" class="mx-8" (click)="reload()" >Refrescar</button>
  </div>
