import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { RatesAcCRXTService } from '../../services/ac-crxt.service';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { AssignTransactionComponent } from 'src/app/dashboard/components/admin-transactions/assign-transaction/assign-transaction.component';
import { ParameterService } from 'src/app/dashboard/services/parameter.service';
import { AssignTransactionsDTO } from 'src/app/dashboard/interfaces/assign-transactions.dto';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { TransaccionService } from 'src/app/dashboard/services/transaccion.service';
import { RejectTransactionModalComponent } from 'src/app/dashboard/components/assigned-transactions/reject-transaction-modal/reject-transaction-modal.component';
import { MatPaginator } from '@angular/material/paginator';
import { RejectTransactionDTO } from 'src/app/dashboard/interfaces/reject-transaction.dto';

@Component({
  selector: 'app-crixto-view',
  templateUrl: './crixto-view.component.html',
  styleUrls: ['./crixto-view.component.sass']
})
export class CrixtoViewComponent implements OnInit, AfterViewInit {
  @ViewChild('dialogTemplate') dialogTemplate!: TemplateRef<any>;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;

  async verifyTransaction(row: any) {
    const spinnerRef = this.spinner.start();
    let verification = await this.transactionsCrixtoService.validateTransferDirect(row.transactionId);
    const codResponse = verification.data.codigoRespuesta;
    console.log(verification);
    let title = '';
    let text = '';
    let icon2: 'success' | 'error' = 'success';
    if (codResponse !== '0000' && codResponse !== '9999') {
      title = 'Transacción fue rechazada';
      text = `La transacción sera rechazada automaticamente.\n\nDetalles:\nMensaje: ${verification.message}\nEstado: ${verification.status}\nCódigo de estado: ${verification.statusCode}`;
      icon2 = 'error';
      let rejectDto: RejectTransactionDTO = {
        transactionID: row.transactionId,
        motivo: 'Transaccion rechazada por el banco'
      };
      await this.transactionService.rejectTransaction(rejectDto);
      Swal.fire({
        icon: icon2,
        title: title,
        text: text,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#1e5a3f'
      });
      this.spinner.stop(spinnerRef);
    } else {
      if (codResponse === '0000') {
        title = 'Transacción Aprobada';
        text = `La transacción ha sido aprobada exitosamente.\n\nDetalles:\nMensaje: ${verification.message}\nEstado: ${verification.status}\nCódigo de estado: ${verification.statusCode}`;
        icon2 = 'success';
      } else if (codResponse === '9999') {
        title = 'Transacción en verificacion';
        text = `La transacción todavia no ha sido confirmada.\n\nDetalles:\nMensaje: ${verification.message}\nEstado: ${verification.status}\nCódigo de estado: ${verification.statusCode}`;
        icon2 = 'error';
      }
      this.spinner.stop(spinnerRef);
      Swal.fire({
        icon: icon2,
        title: title,
        text: text,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#1e5a3f'
      });
    }
  }
  showData(data: any): void {
    const formattedData = this.formatJson(data);
    const htmlFormattedData = `<pre style="
        text-align: left;
        background: #2d2d2d;
        color: #f8f8f2;
        padding: 15px;
        border-radius: 8px;
        white-space: pre-wrap;
        font-size: 14px;
        font-family: 'Courier New', Courier, monospace;
        overflow-x: auto;
        max-height: 400px;">${formattedData}</pre>`;
    Swal.fire({
      title: 'Información',
      html: htmlFormattedData,
      icon: 'info',
      confirmButtonText: 'Close',
      width: '60%',
      customClass: {
        popup: 'swal2-overflow',
      }
    });
  }

  transactions: any[] = [];
  dataSource = new MatTableDataSource<any>([]);
  page: number = 1;
  limit: number = 10;
  totalPages: number = 0;
  total: number = 0;
  idNumberDestination: string = '';
  from: string = '';
  to: string = '';
  toDate: Date = new Date();
  selectedData: any = null;
  transaction: any;

  columns = [
    { columnDef: 'id', header: 'ID', cell: (element: any) => `${element.id}` },
    { columnDef: 'transaction_id', header: 'Transaction ID', cell: (element: any) => `${element.transactionId}` },
    {
      columnDef: 'tipo',
      header: 'Tipo',
      cell: (element: any) => this.getTransactionType(element.tipo)
    },
    {
      columnDef: 'idUsuario',
      header: 'ID USUARIO',
      cell: (element: any) => this.getTransactionType(element.data.infoUsuario.userId)
    },
    {
      columnDef: 'transactionStatus',
      header: 'Estado Transaccion',
      cell: (element: any) => this.getTransactionType(element.transaccion.estado)
    },
    {
      columnDef: 'beneficiario',
      header: 'Beneficiario',
      cell: (element: any) => this.getTransactionType(element.data.infoUsuario.name)
    },
    {
      columnDef: 'monto',
      header: 'Monto',
      cell: (element: any) => this.getTransactionType(element.data.infoUsuario.amount)
    },
    {
      columnDef: 'documento',
      header: 'Documento',
      cell: (element: any) => this.getTransactionType(element.data.infoUsuario.idNumberDestino)
    },
    { columnDef: 'fecha', header: 'Fecha', cell: (element: any) => `${this.datePipe.transform(element.fecha, 'dd/MM/yyyy')}` },
    {
      columnDef: 'status',
      header: 'Estado Crixto',
      cell: (element: any) =>
        `<span class="${this.getStatusClass(element.status)}">
          ${element.status ? 'Realizada' : 'No enviada'}
        </span>`
    },
    { columnDef: 'mensaje', header: 'Mensaje', cell: (element: any) => `${element.mensaje}` },
  ];

  displayedColumns = [...this.columns.map(column => column.columnDef).filter(col => col !== 'id'), 'data', 'actions'];


  constructor(
    private datePipe: DatePipe,
    private spinner: SpinnerService,
    private transactionsCrixtoService: RatesAcCRXTService,
    private dialog: MatDialog,
    private parameterService: ParameterService,
    private auth: CognitoService,
    private readonly transactionService: TransaccionService,
    private cdRef: ChangeDetectorRef,
  ) {
  }
  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  async ngOnInit() {
    await this.loadTransactions();
    const today = new Date();
    this.to = this.formatDateToString(today, 'to');
  }

  async loadTransactions() {
    try {
      const formattedFrom = this.from ? this.formatDateToString(new Date(this.from), 'from') : undefined;
      const formattedTo = this.to ? this.formatDateToString(new Date(this.to), 'to') : undefined;

      const response = await this.transactionsCrixtoService.getTransactions(
        this.page,
        this.limit,
        this.idNumberDestination,
        formattedFrom,
        formattedTo
      );

      // Actualiza las variables con la respuesta del servicio
      this.total = response.total;        // Total: 23
      this.transactions = response.transactions;
      this.totalPages = response.totalPages; // Por ejemplo, 3

      // Actualiza la data de la DataSource (sin asignarle el paginator)
      this.dataSource.data = this.transactions;

      // Forzar la detección de cambios para que el paginador actualice sus valores
      this.cdRef.detectChanges();

      console.log(`📊 Total: ${this.total}, Total Pages: ${this.totalPages}`);
    } catch (error) {
      console.error('❌ Error al obtener transacciones:', error);
    }
  }


  changePage(event: PageEvent) {
    const spinnerRef = this.spinner.start();
    this.page = event.pageIndex + 1;
    this.limit = event.pageSize;
    this.loadTransactions();
    this.spinner.stop(spinnerRef);
  }

  changeLimit(newLimit: number) {
    this.limit = newLimit;
    this.page = 1;
    this.loadTransactions();
  }

  async reload(): Promise<void> {
    this.page = 1;
    this.loadTransactions();
  }

  async confirmAssignTransaction(row: any) {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "¿Deseas reenviar esta transacción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1e5a3f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, reenviar',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      this.assignTransaction(row);
    }
  }

  async assignTransaction(row: any) {
    let workingAccount = await this.parameterService.getParameter('CUENTA_TRABAJO_CRIXTO') as unknown as number;
    const cognitoUser = await this.auth.getUser();
    const email = cognitoUser.attributes.email;
    const payload: AssignTransactionsDTO = {
      operatorEmail: email,
      workingAccountID: workingAccount,
      transactions: row.transactionId
    }
    const spinnerRef = this.spinner.start();
    try {
      const response = await this.transactionService.asignTransactions(payload);
      Swal.fire({
        icon: 'success',
        title: 'Transacciones reenviada',
        text: 'las transacciones se ha reenviado exitosamente',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#1e5a3f',

      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al intentar reenviar la transaccion',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#1e5a3f',

      });
    }
    this.spinner.stop(spinnerRef);
  }

  openAssignDialog(row: any) {
    const dialogRef = this.dialog.open(AssignTransactionComponent, {
      data: {
        trxId: row.transactionId, type: 'reassign_crixto'
      },
      maxHeight: '70%',
      maxWidth: '826px',
      minWidth: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        this.loadTransactions();
      }
    });
  }

  openRejectTransactionModal(row: any) {
    if (!this.transaction) {
      this.transaction = {};
    }
    this.transaction.id = row.transactionId;
    const dialogRef = this.dialog.open(RejectTransactionModalComponent, {
      data: this.transaction,
      maxHeight: '70%',
      maxWidth: '826px',
      minWidth: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        this.loadTransactions();
      }
    });
  }

  async applyFilters() {
    this.page = 1;
    this.loadTransactions();
  }

  formatDateToString(date: Date, type: 'from' | 'to'): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    const time = type === 'from' ? '00:00:00' : '23:59:59';

    return `${year}-${month}-${day} ${time}`;
  }

  getStatusClass(status: boolean): string {
    return status ? 'bg-aguacate-highlight text-white px-2 py-1 rounded' : 'bg-red-500 text-white px-2 py-1 rounded';
  }

  getTransactionType(tipo: string): string {
    const typesMap: { [key: string]: string } = {
      'paymentCrixtoMovilPay': 'Pago Móvil',
      'paymentCrixtoTransferDirect': 'Transferencia Directa'
    };
    return typesMap[tipo] || tipo;
  }


  formatJson(json: any): string {
    const jsonString = JSON.stringify(json, null, 2);
    return jsonString.replace(
      /("(.*?)")(: )?("[^"]*"|\d+|true|false|null)/g,
      (_match, key, _keyName, colon, value) => {
        return `<span style="color: #f78c6c;">${key}</span>${colon ? '<span style="color: white;">:</span>' : ''} <span style="color: #89ddff;">${value}</span>`;
      }
    );
  }
}
