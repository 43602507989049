<div class="p-6 space-y-4 md:space-y-6 sm:p-8">
  <div class="container flex flex-row items-center">
    <h1 class="text-xl font-bold">
      Actualizar Tasas
    </h1>
  </div>
  <form *ngIf="formCuenta" class="space-y-4 md:space-y-6" [formGroup]="formCuenta">
    <div class="grid grid-cols-2 gap-4">
      <mat-form-field>
        <mat-label>Tasa CLP</mat-label>
        <input type="number" matInput formControlName="tasaCLP" step="0.1" [readonly]="true" class="readonly-input"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Porcentaje comisión</mat-label>
        <input type="number" matInput formControlName="porcentajeComision" step="0.1"/>
      </mat-form-field>
    </div>
    <div class="grid grid-cols-2 gap-4">
      <mat-form-field>
        <mat-label>Tasa CRIXTO</mat-label>
        <input type="number" matInput formControlName="tasaCRXT" step="0.1"/>
      </mat-form-field>
    </div>
    <div class="container flex flex-col items-center">
      <button mat-raised-button color="primary" (click)="actualizarTasa()"
      class="w-1/2 text-white focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" [disabled]="formCuenta.invalid || formCuenta.hasError('outOfRange')">
        Guardar
      </button>
    </div>
  </form>
</div>
