<div class="w-[800px] px-16 py-10  rounded-3xl uploader-status flex flex-col items-center justify-center align-middle">

  <h2 class="text-2xl font-bold self-start">Asignar transacciones</h2>

  <h3 *ngIf="type===''" class="text-lg font-bold my-4  self-start">Transacciones seleccionadas:</h3>
  <table mat-table [dataSource]="dataSource" class="w-10/12 m-4" *ngIf="dataSource && type===''">
    <ng-container matColumnDef="seleccion">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">Seleccionar</th>
      <td mat-cell *matCellDef="let element">
        <!-- <mat-checkbox (change)="change()" [disabled]="element.estado !== 'POR_PROCESAR'" [(ngModel)]="element.seleccionado" class="example-margin"></mat-checkbox> -->
      </td>
    </ng-container>
    <ng-container
      *ngFor="let column of columns"
      [matColumnDef]="column.columnDef"
    >
      <th mat-header-cell *matHeaderCellDef style="text-align:center" >{{column.header}}</th>
      <td mat-cell *matCellDef="let row">{{column.cell(row)}}</td>
    </ng-container>
    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">Acciones</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button mat-icon-button color="primary" aria-label="remover" title="remover" [disabled]="dataSource.data.length === 1" (click)="remover(i)">
          <mat-icon>remove</mat-icon>
        </button>
      </td>
    </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div class="mt-6 ml-4">
    <mat-label class="font-bold mt-10 text-lg mb-4 py-4 ">Selecciona la cuenta de trabajo</mat-label>
    <mat-select class="w-[700px] mt-3" [(ngModel)]="selectedWorkingAccount" name="selectedWorkingAccount" ngDefaultControl >
      <mat-option [value]="item"   *ngFor="let item of workingAccountList">
        <span class="text-bold" *ngIf="item.banco?.nombre === 'BINANCE'">{{item.banco?.nombre}} -  {{item.nombre | uppercase}} {{item.apellidos | uppercase}} </span>
        <span class="text-bold" *ngIf="item.banco?.nombre !== 'BINANCE'" >{{item.banco?.nombre}} {{item.tipoCuenta?.nombre}}: {{item.numeroCuenta}}  - {{item.nombre | uppercase}} {{item.apellidos | uppercase}}</span>
      </mat-option>
    </mat-select>
  </div>
  <div class="flex flex-row justify-center gap-10 w-full mt-10">
    <button type="button"  mat-raised-button color="primary" class="mt-4 w-1/2 font-semibold text-lg rounded-lg px-5 py-6 text-center"   (click)="assignTransaction()" [disabled]="!selectedWorkingAccount"
    >
    Asignar transacciones
  </button>
    <button type="button" mat-raised-button color="basic" (click)="onNoClick()"
      class=" w-1/2 mt-4 font-semibold text-lg rounded-lg px-5 py-6 text-center">
      Cancelar
    </button>
  </div>

</div>
